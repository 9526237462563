import { API, Auth } from "aws-amplify";
import { Course } from "../../api/graphql/schemas";
import { checkIfUserAuthenticated } from "../../utils/AuthUtil";
import { listCourses_Prices_Admin_Custom } from "./CustomAdminQueries";

const ADMIN_API_NAME = "AdminQueries";

export const createOrUpdateSubscriptionModel = async (body: any) => {
  return await API.post(
    ADMIN_API_NAME,
    "/createOrUpdateSubscriptionModel",
    await takeBodyApplyAuthHeader(body)
  );
};

// Retrieves all courses in the system.
export const getAllCourses_Prices_Admin_API = async (): Promise<
  Course[] | undefined
> => {
  type ListCoursesResult = {
    data: {
      listCourses: {
        items: Course[];
      };
    };
  };

  let courseArrayResult;
  try {
    const graphqlResult = await makeGraphqlQuery(
      listCourses_Prices_Admin_Custom
    );

    courseArrayResult = (graphqlResult as ListCoursesResult)?.data?.listCourses
      ?.items;
  } catch (error) {
    console.log(error);
  }

  return courseArrayResult;
};

const takeBodyApplyAuthHeader = async (body: any) => {
  const session = await Auth.currentSession();

  // Next, get the ID token from the session
  const idToken = session.getIdToken().getJwtToken();

  let headers = {
    Authorization: idToken,
  };

  return {
    body: body,
    headers: headers,
  };
};

const makeGraphqlQuery = async (query: any, input?: any) => {
  let isUserAuthenticated = await checkIfUserAuthenticated();
  const graphqlResult = await API.graphql({
    query: query,
    variables: input,
    authMode: isUserAuthenticated ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
  });

  return graphqlResult;
};
