import React from "react";
import { getFullImagePath } from "../../utils/ImageUtil";
import signInRequiredImage from "../../assets/sign-in-required.svg";
import "./SigninRequired.css";

type SigninRequiredProps = {
  message: string;
};

export const SignnRequired: React.FunctionComponent<SigninRequiredProps> = ({
  message,
}) => {
  return (
    <div className="signin-Rqrd-cntnr">
      <div className="message">{message}</div>
      <img className="signin-Rqrd-img" src={signInRequiredImage} />
    </div>
  );
};
