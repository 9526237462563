export const getFormattedDate = (timestamp?: number) => {
  if (!timestamp) {
    return "-";
  }
  const dateVar = new Date(timestamp * 1000);

  return dateVar.toLocaleDateString();
};

export const isLocalHostFunction = () => {
  return Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );
};

export const isStagingFunction = () => {
  return (
    window.location.hostname.includes("staging") ||
    window.location.hostname.includes("Staging")
  );
};

export const isProductionFunction = () => {
  if (isLocalHostFunction() || isStagingFunction()) {
    return false;
  }

  return true;
};

export const getRedirectUrl = (
  local: string,
  staging: string,
  production: string
) => {
  const isLocalHost = isLocalHostFunction();
  const isStaging = isStagingFunction();

  if (isLocalHost) {
    return local;
  } else if (isStaging) {
    return staging;
  } else {
    return production;
  }
};
