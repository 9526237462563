import "./NavigationHeader.css";

import React, { useEffect, useState } from "react";

import { ProfileMenu } from "../../models/account/ProfileMenu";
import { Link } from "react-router-dom";
import { SignInModel } from "../../models/account/SignInModel";
import logo from "../../assets/sweamers-logo.png";
import { useSelector } from "react-redux";

type NavigationHeaderProps = {};

export const NavigationHeader: React.FunctionComponent<
  NavigationHeaderProps
> = ({}) => {
  const [showSignInModel, setShowSignInModel] = useState<boolean>(false);

  const user = useSelector((state: any) => state.userState.user);

  const onDismissSignInModel = () => {
    setShowSignInModel(false);
  };

  useEffect(() => {
    if (user) {
      setShowSignInModel(false);
    }
  }, [user]);

  return (
    <header>
      <div className="nav-hdr">
        <Link to={`/home`}>
          <img className="logo" src={logo} alt="sweamers logo" />
        </Link>
        <div className="mid-nav-hdr">
          <Link to={`/courses`}>
            <div>Courses</div>
          </Link>
          {/* <Link to={`/contactus`}>
            <div>Contact us</div>
          </Link> */}
        </div>
        <div className="rgt-nav-hdr">
          {user ? (
            <ProfileMenu userDisplayID={user?.email} />
          ) : (
            <div
              className="signin-button"
              onClick={() => setShowSignInModel(true)}
            >
              Sign In
            </div>
          )}
        </div>
      </div>
      {/* TODO: Move to parallel to home page when redux is implemented */}
      {showSignInModel && (
        <SignInModel onDismissSignInModel={onDismissSignInModel} />
      )}
    </header>
  );
};
