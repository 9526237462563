import "./Account.css";

import React, { useState, useEffect, useRef } from "react";

import { signOutFromApp } from "../../utils/AuthUtil";
import userIcon from "../../assets/profile_icon.svg";
import { Link } from "react-router-dom";

type ProfileMenuProps = {
  userDisplayID?: string;
};

export const ProfileMenu: React.FunctionComponent<ProfileMenuProps> = ({
  userDisplayID,
}) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const profileMenuRef = useRef<HTMLDivElement>(null);

  const toggleUserMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (
        profileMenuRef &&
        profileMenuRef.current &&
        !event.composedPath().includes(profileMenuRef.current)
      ) {
        setShowMenu(false);
      }
    };
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [profileMenuRef]);

  return (
    <div className="user-cntr">
      <div ref={profileMenuRef} className="user-img" onClick={toggleUserMenu}>
        <img src={userIcon} alt="profile" />
      </div>
      {showMenu && (
        <div className="usr-menu">
          <div className="menu-item border">
            <div>Signed in as</div>
            <div className="user-id">{userDisplayID}</div>
          </div>
          <div className="menu-item option border">
            <Link to={`/account`}>
              <div>Account</div>
            </Link>
          </div>
          <div className="menu-item option border" onClick={signOutFromApp}>
            Sign out
          </div>
        </div>
      )}
    </div>
  );
};
