import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { getSubtopicAndContentAPI } from "../../../api/CourseApis";
import { CoursesAndPrices } from "../../components/CoursesAndPrices";
import { private_createMultipleCourseAPI } from "../../_mock_/MockDataCreationApi";

import "./AdminPage.css";

export const AdminPage: React.FunctionComponent = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  let sectionParam = searchParams.get("section");

  const createProperMockData = () => {
    private_createMultipleCourseAPI();
  };

  const randomFunction = () => {
    getSubtopicAndContentAPI("df38ee8f-92f2-49d7-8d47-dbe7bdf996ec");
  };

  return (
    <div className="admin-page">
      <div>
        <Link to={`/admin?section=actions`}>
          <div>Actions</div>
        </Link>
        <Link to={`/admin?section=subscriptionmodel`}>
          <div>Subscriptoin Models</div>
        </Link>
      </div>
      {(!sectionParam || sectionParam === "actions") && (
        <div>
          <button onClick={createProperMockData}>Create Mock Courses</button>
          <br />
          <button onClick={randomFunction}>updatePrice</button>
        </div>
      )}
      {sectionParam === "subscriptionmodel" && <CoursesAndPrices />}
    </div>
  );
};
