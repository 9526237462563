import "./Account.css";
import "@aws-amplify/ui-react/styles.css";

import { Authenticator } from "@aws-amplify/ui-react";
import React from "react";
import closeIcon from "../../assets/close_icon.svg";

type SignInModelProps = {
  onDismissSignInModel: () => void;
};

export const SignInModel: React.FunctionComponent<SignInModelProps> = ({
  onDismissSignInModel,
}) => {
  return (
    <div className="sign-in-model">
      <div className="model-bg">
        <div className="dismiss-button" onClick={onDismissSignInModel}>
          <img src={closeIcon} alt="dismiss" />
        </div>
        <Authenticator />
      </div>
    </div>
  );
};
