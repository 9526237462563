import { Auth, Hub } from "aws-amplify";

Hub.listen("auth", (data) => {
  switch (data.payload.event) {
    case "signIn":
      // This case may happen
      // Case 1: Normal Sign in to the app.
      // Step 2: Sign in via 3rd party provider.
      window.location.reload();
      break;
    case "signUp":
      // No Action needed from the app.
      break;
    case "signOut":
      window.location.reload();
      break;
    case "signIn_failure":
      // This case may happen
      // Case 1:
      //     Step 1: Sign up but do not put in my verification code.
      //     Step 2: Refresh the app and then use email and password to log in.
      //     Step 3: App would ask for verification code and publish this event.
      // No Action needed from the app.
      break;
    case "configured":
    // No Action needed from the app.
  }
});

export const signOutFromApp = () => {
  try {
    Auth.signOut().then(
      () => {},
      (error) => {}
    );
  } catch (error) {
    throw new Error("sign out failed");
  }
};

export const checkIfUserAuthenticated = async () => {
  let isUserAuthenticated = false;
  try {
    await Auth.currentAuthenticatedUser();
    isUserAuthenticated = true;
  } catch (error) {}

  return isUserAuthenticated;
};
