import React from "react";
import "./NoMoney.css";
import noMoneyImage from "../../assets/dog.svg";

type NoMoneyProps = {
  message: string;
};

export const NoMoney: React.FunctionComponent<NoMoneyProps> = ({ message }) => {
  return (
    <div className="no-money-cntnr">
      <div className="message">{message}</div>
      <img className="no-money-img" src={noMoneyImage} />
    </div>
  );
};
