import { Custom_Subscription, Custom_User } from "../api/CustomSchemas";
import {
  Course,
  ReleaseStatus,
  Topic,
  UserAccessType,
} from "../api/graphql/schemas";
import { DisplayStatus } from "../types/DisplayTypes";

// Returns selected Topic Id.
export const getSelectecTopicFromCourse = (
  courseArg?: Course | null,
  topicIdArg?: string | null
) => {
  let topic = null;
  // Writing this crazy code due to some complication error on simple version.
  if (
    courseArg &&
    courseArg.chapters &&
    courseArg.chapters.items &&
    courseArg.chapters.items.length > 0 &&
    courseArg.chapters.items[0] &&
    courseArg.chapters.items[0].topics &&
    courseArg.chapters.items[0].topics.items &&
    courseArg.chapters.items[0].topics.items.length > 0 &&
    courseArg.chapters.items[0].topics.items[0]
  ) {
    topic = courseArg.chapters.items[0].topics.items[0];
  }

  if (topicIdArg) {
    courseArg?.chapters?.items?.map((chapter) => {
      chapter?.topics?.items?.map((item) => {
        if (item && item.id === topicIdArg) {
          topic = item;
        }
      });
    });
  }
  return topic;
};

export const releaseStatusToUserFriendlyName = (
  releaseStatus: ReleaseStatus
) => {
  // Do not return anythig for GA status.
  if (releaseStatus === ReleaseStatus.COMING_SOON) {
    return "Coming Soon";
  } else if (releaseStatus === ReleaseStatus.ALPHA) {
    return "Alpha";
  } else if (releaseStatus === ReleaseStatus.BETA) {
    return "Beta";
  } else {
    return "";
  }
};

export const courseComparator = (course1: Course, course2: Course) => {
  return course1.sortOrder - course2.sortOrder;
};

export const getDisplayStatus = (
  arg: any,
  user: Custom_User,
  subscription?: Custom_Subscription | null
): DisplayStatus => {
  if (
    arg.releaseStatus === ReleaseStatus.NONE ||
    arg.userAccessType === UserAccessType.NONE
  ) {
    return DisplayStatus.HIDE;
  }

  if (arg.releaseStatus === ReleaseStatus.COMING_SOON) {
    return DisplayStatus.COMING_SOON;
  }

  const isUserAuthenticatedArg = user != null && user != undefined;

  if (arg.userAccessType === UserAccessType.GUEST) {
    return DisplayStatus.CONTINUE_AS_USUAL;
  } else if (arg.userAccessType === UserAccessType.SIGNED_IN) {
    return isUserAuthenticatedArg
      ? DisplayStatus.CONTINUE_AS_USUAL
      : DisplayStatus.SIGN_IN_REQUIRED;
  } else if (arg.userAccessType === UserAccessType.PAID) {
    if (!isUserAuthenticatedArg) {
      return DisplayStatus.SIGN_IN_AND_PAYMENT_REQUIRED;
    } else if (subscription === undefined) {
      // We have not been able to fetch subscriptions yet.
      return DisplayStatus.HIDE;
    } else if (subscription === null) {
      return DisplayStatus.PAYMENT_REQUIRED;
    } else {
      return DisplayStatus.CONTINUE_AS_USUAL;
    }
  }

  return DisplayStatus.HIDE;
};
