import "./CourseNavigator.css";

import { Chapter, Course, Topic } from "../../api/graphql/schemas";
import React, { useState } from "react";

import { ChapterAccordion } from "../chapter-accordion/ChapterAccordion";
import arrowLeft from "../../assets/sidebar-panel-collapse-icon.svg";
import arrowRight from "../../assets/sidebar-panel-expand-icon.svg";
import { useSelector } from "react-redux";

type CourseNavigatorProps = {};

export const CourseNavigator: React.FunctionComponent<
  CourseNavigatorProps
> = ({}) => {
  const [isNavOpen, setIsNavOpen] = useState<boolean>(true);
  const course = useSelector((state: any) => state.courseState.selectedCourse);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <>
      <div
        className={isNavOpen ? "nav-tgl" : "nav-tgl close"}
        onClick={toggleNav}
      >
        {!isNavOpen && <img src={arrowRight} alt="open nav" />}
        {isNavOpen && <img src={arrowLeft} alt="close nav" />}
      </div>
      <div className={isNavOpen ? "crs-nav" : "crs-nav hidden"}>
        <div className="crs-name">{course.name}</div>
        {course?.chapters?.items?.map(
          (chapter: Chapter) =>
            chapter && (
              <ChapterAccordion
                key={chapter.id}
                chapter={chapter}
                courseId={course.id}
              />
            )
        )}
      </div>
    </>
  );
};
