/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type Course = {
  __typename: "Course",
  id: string,
  name: string,
  releaseStatus: ReleaseStatus,
  userAccessType: UserAccessType,
  image?: string | null,
  sortOrder: number,
  chapters?: ModelChapterConnection | null,
  subscriptionModels?: ModelCourseSubscriptionModelManyToManyRelationConnection | null,
  createdAt: string,
  updatedAt: string,
};

export enum ReleaseStatus {
  NONE = "NONE",
  COMING_SOON = "COMING_SOON",
  ALPHA = "ALPHA",
  BETA = "BETA",
  GA = "GA",
}


export enum UserAccessType {
  NONE = "NONE",
  GUEST = "GUEST",
  SIGNED_IN = "SIGNED_IN",
  PAID = "PAID",
}


export type ModelChapterConnection = {
  __typename: "ModelChapterConnection",
  items:  Array<Chapter | null >,
  nextToken?: string | null,
};

export type Chapter = {
  __typename: "Chapter",
  id: string,
  name: string,
  releaseStatus: ReleaseStatus,
  userAccessType: UserAccessType,
  courseId: string,
  sortOrder: number,
  course?: Course | null,
  topics?: ModelTopicConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelTopicConnection = {
  __typename: "ModelTopicConnection",
  items:  Array<Topic | null >,
  nextToken?: string | null,
};

export type Topic = {
  __typename: "Topic",
  id: string,
  name: string,
  releaseStatus: ReleaseStatus,
  userAccessType: UserAccessType,
  chapterId: string,
  sortOrder: number,
  chapter?: Chapter | null,
  subtopics?: ModelSubtopicConnection | null,
  courseId: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelSubtopicConnection = {
  __typename: "ModelSubtopicConnection",
  items:  Array<Subtopic | null >,
  nextToken?: string | null,
};

export type Subtopic = {
  __typename: "Subtopic",
  id: string,
  name: string,
  releaseStatus: ReleaseStatus,
  userAccessType: UserAccessType,
  topicId: string,
  sortOrder: number,
  topic?: Topic | null,
  contentIdentifier?: string | null,
  subtopicContent?: string | null,
  courseId: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelCourseSubscriptionModelManyToManyRelationConnection = {
  __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
  items:  Array<CourseSubscriptionModelManyToManyRelation | null >,
  nextToken?: string | null,
};

export type CourseSubscriptionModelManyToManyRelation = {
  __typename: "CourseSubscriptionModelManyToManyRelation",
  id: string,
  courseId: string,
  subscriptionModelId: string,
  course: Course,
  subscriptionModel: SubscriptionModel,
  createdAt: string,
  updatedAt: string,
};

export type SubscriptionModel = {
  __typename: "SubscriptionModel",
  id: string,
  amountInLowestDenomination: number,
  currency: Currency,
  isRecurring: boolean,
  timeInterval: TimeInterval,
  stripePriceId: string,
  courseId: string,
  courses?: ModelCourseSubscriptionModelManyToManyRelationConnection | null,
  createdAt: string,
  updatedAt: string,
};

export enum Currency {
  USD = "USD",
}


export enum TimeInterval {
  ONE_MONTH = "ONE_MONTH",
  THREE_MONTHS = "THREE_MONTHS",
}


export type ModelCourseFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  releaseStatus?: ModelReleaseStatusInput | null,
  userAccessType?: ModelUserAccessTypeInput | null,
  image?: ModelStringInput | null,
  sortOrder?: ModelIntInput | null,
  and?: Array< ModelCourseFilterInput | null > | null,
  or?: Array< ModelCourseFilterInput | null > | null,
  not?: ModelCourseFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelReleaseStatusInput = {
  eq?: ReleaseStatus | null,
  ne?: ReleaseStatus | null,
};

export type ModelUserAccessTypeInput = {
  eq?: UserAccessType | null,
  ne?: UserAccessType | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelCourseConnection = {
  __typename: "ModelCourseConnection",
  items:  Array<Course | null >,
  nextToken?: string | null,
};

export type ModelChapterFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  releaseStatus?: ModelReleaseStatusInput | null,
  userAccessType?: ModelUserAccessTypeInput | null,
  courseId?: ModelIDInput | null,
  sortOrder?: ModelIntInput | null,
  and?: Array< ModelChapterFilterInput | null > | null,
  or?: Array< ModelChapterFilterInput | null > | null,
  not?: ModelChapterFilterInput | null,
};

export type ModelTopicFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  releaseStatus?: ModelReleaseStatusInput | null,
  userAccessType?: ModelUserAccessTypeInput | null,
  chapterId?: ModelIDInput | null,
  sortOrder?: ModelIntInput | null,
  courseId?: ModelIDInput | null,
  and?: Array< ModelTopicFilterInput | null > | null,
  or?: Array< ModelTopicFilterInput | null > | null,
  not?: ModelTopicFilterInput | null,
};

export type ModelSubtopicFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  releaseStatus?: ModelReleaseStatusInput | null,
  userAccessType?: ModelUserAccessTypeInput | null,
  topicId?: ModelIDInput | null,
  sortOrder?: ModelIntInput | null,
  contentIdentifier?: ModelStringInput | null,
  subtopicContent?: ModelStringInput | null,
  courseId?: ModelIDInput | null,
  and?: Array< ModelSubtopicFilterInput | null > | null,
  or?: Array< ModelSubtopicFilterInput | null > | null,
  not?: ModelSubtopicFilterInput | null,
};

export type User = {
  __typename: "User",
  username: string,
  email: string,
  name: string,
  stripeCustomerId?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelCourseSubscriptionModelManyToManyRelationFilterInput = {
  id?: ModelIDInput | null,
  courseId?: ModelIDInput | null,
  subscriptionModelId?: ModelIDInput | null,
  and?: Array< ModelCourseSubscriptionModelManyToManyRelationFilterInput | null > | null,
  or?: Array< ModelCourseSubscriptionModelManyToManyRelationFilterInput | null > | null,
  not?: ModelCourseSubscriptionModelManyToManyRelationFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type GetCourseQueryVariables = {
  id: string,
};

export type GetCourseQuery = {
  getCourse?:  {
    __typename: "Course",
    id: string,
    name: string,
    releaseStatus: ReleaseStatus,
    userAccessType: UserAccessType,
    image?: string | null,
    sortOrder: number,
    chapters?:  {
      __typename: "ModelChapterConnection",
      items:  Array< {
        __typename: "Chapter",
        id: string,
        name: string,
        releaseStatus: ReleaseStatus,
        userAccessType: UserAccessType,
        courseId: string,
        sortOrder: number,
        course?:  {
          __typename: "Course",
          id: string,
          name: string,
          releaseStatus: ReleaseStatus,
          userAccessType: UserAccessType,
          image?: string | null,
          sortOrder: number,
          chapters?:  {
            __typename: "ModelChapterConnection",
            nextToken?: string | null,
          } | null,
          subscriptionModels?:  {
            __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        topics?:  {
          __typename: "ModelTopicConnection",
          items:  Array< {
            __typename: "Topic",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            chapterId: string,
            sortOrder: number,
            courseId: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscriptionModels?:  {
      __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
      items:  Array< {
        __typename: "CourseSubscriptionModelManyToManyRelation",
        id: string,
        courseId: string,
        subscriptionModelId: string,
        course:  {
          __typename: "Course",
          id: string,
          name: string,
          releaseStatus: ReleaseStatus,
          userAccessType: UserAccessType,
          image?: string | null,
          sortOrder: number,
          chapters?:  {
            __typename: "ModelChapterConnection",
            nextToken?: string | null,
          } | null,
          subscriptionModels?:  {
            __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        subscriptionModel:  {
          __typename: "SubscriptionModel",
          id: string,
          amountInLowestDenomination: number,
          currency: Currency,
          isRecurring: boolean,
          timeInterval: TimeInterval,
          stripePriceId: string,
          courseId: string,
          courses?:  {
            __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCoursesQueryVariables = {
  filter?: ModelCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCoursesQuery = {
  listCourses?:  {
    __typename: "ModelCourseConnection",
    items:  Array< {
      __typename: "Course",
      id: string,
      name: string,
      releaseStatus: ReleaseStatus,
      userAccessType: UserAccessType,
      image?: string | null,
      sortOrder: number,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          name: string,
          releaseStatus: ReleaseStatus,
          userAccessType: UserAccessType,
          courseId: string,
          sortOrder: number,
          course?:  {
            __typename: "Course",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            image?: string | null,
            sortOrder: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          topics?:  {
            __typename: "ModelTopicConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscriptionModels?:  {
        __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
        items:  Array< {
          __typename: "CourseSubscriptionModelManyToManyRelation",
          id: string,
          courseId: string,
          subscriptionModelId: string,
          course:  {
            __typename: "Course",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            image?: string | null,
            sortOrder: number,
            createdAt: string,
            updatedAt: string,
          },
          subscriptionModel:  {
            __typename: "SubscriptionModel",
            id: string,
            amountInLowestDenomination: number,
            currency: Currency,
            isRecurring: boolean,
            timeInterval: TimeInterval,
            stripePriceId: string,
            courseId: string,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetChapterQueryVariables = {
  id: string,
};

export type GetChapterQuery = {
  getChapter?:  {
    __typename: "Chapter",
    id: string,
    name: string,
    releaseStatus: ReleaseStatus,
    userAccessType: UserAccessType,
    courseId: string,
    sortOrder: number,
    course?:  {
      __typename: "Course",
      id: string,
      name: string,
      releaseStatus: ReleaseStatus,
      userAccessType: UserAccessType,
      image?: string | null,
      sortOrder: number,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          name: string,
          releaseStatus: ReleaseStatus,
          userAccessType: UserAccessType,
          courseId: string,
          sortOrder: number,
          course?:  {
            __typename: "Course",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            image?: string | null,
            sortOrder: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          topics?:  {
            __typename: "ModelTopicConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscriptionModels?:  {
        __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
        items:  Array< {
          __typename: "CourseSubscriptionModelManyToManyRelation",
          id: string,
          courseId: string,
          subscriptionModelId: string,
          course:  {
            __typename: "Course",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            image?: string | null,
            sortOrder: number,
            createdAt: string,
            updatedAt: string,
          },
          subscriptionModel:  {
            __typename: "SubscriptionModel",
            id: string,
            amountInLowestDenomination: number,
            currency: Currency,
            isRecurring: boolean,
            timeInterval: TimeInterval,
            stripePriceId: string,
            courseId: string,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        releaseStatus: ReleaseStatus,
        userAccessType: UserAccessType,
        chapterId: string,
        sortOrder: number,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          name: string,
          releaseStatus: ReleaseStatus,
          userAccessType: UserAccessType,
          courseId: string,
          sortOrder: number,
          course?:  {
            __typename: "Course",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            image?: string | null,
            sortOrder: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          topics?:  {
            __typename: "ModelTopicConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        subtopics?:  {
          __typename: "ModelSubtopicConnection",
          items:  Array< {
            __typename: "Subtopic",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            topicId: string,
            sortOrder: number,
            contentIdentifier?: string | null,
            subtopicContent?: string | null,
            courseId: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        courseId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListChaptersQueryVariables = {
  filter?: ModelChapterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChaptersQuery = {
  listChapters?:  {
    __typename: "ModelChapterConnection",
    items:  Array< {
      __typename: "Chapter",
      id: string,
      name: string,
      releaseStatus: ReleaseStatus,
      userAccessType: UserAccessType,
      courseId: string,
      sortOrder: number,
      course?:  {
        __typename: "Course",
        id: string,
        name: string,
        releaseStatus: ReleaseStatus,
        userAccessType: UserAccessType,
        image?: string | null,
        sortOrder: number,
        chapters?:  {
          __typename: "ModelChapterConnection",
          items:  Array< {
            __typename: "Chapter",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            courseId: string,
            sortOrder: number,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        subscriptionModels?:  {
          __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
          items:  Array< {
            __typename: "CourseSubscriptionModelManyToManyRelation",
            id: string,
            courseId: string,
            subscriptionModelId: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        items:  Array< {
          __typename: "Topic",
          id: string,
          name: string,
          releaseStatus: ReleaseStatus,
          userAccessType: UserAccessType,
          chapterId: string,
          sortOrder: number,
          chapter?:  {
            __typename: "Chapter",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            courseId: string,
            sortOrder: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          subtopics?:  {
            __typename: "ModelSubtopicConnection",
            nextToken?: string | null,
          } | null,
          courseId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTopicQueryVariables = {
  id: string,
};

export type GetTopicQuery = {
  getTopic?:  {
    __typename: "Topic",
    id: string,
    name: string,
    releaseStatus: ReleaseStatus,
    userAccessType: UserAccessType,
    chapterId: string,
    sortOrder: number,
    chapter?:  {
      __typename: "Chapter",
      id: string,
      name: string,
      releaseStatus: ReleaseStatus,
      userAccessType: UserAccessType,
      courseId: string,
      sortOrder: number,
      course?:  {
        __typename: "Course",
        id: string,
        name: string,
        releaseStatus: ReleaseStatus,
        userAccessType: UserAccessType,
        image?: string | null,
        sortOrder: number,
        chapters?:  {
          __typename: "ModelChapterConnection",
          items:  Array< {
            __typename: "Chapter",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            courseId: string,
            sortOrder: number,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        subscriptionModels?:  {
          __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
          items:  Array< {
            __typename: "CourseSubscriptionModelManyToManyRelation",
            id: string,
            courseId: string,
            subscriptionModelId: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        items:  Array< {
          __typename: "Topic",
          id: string,
          name: string,
          releaseStatus: ReleaseStatus,
          userAccessType: UserAccessType,
          chapterId: string,
          sortOrder: number,
          chapter?:  {
            __typename: "Chapter",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            courseId: string,
            sortOrder: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          subtopics?:  {
            __typename: "ModelSubtopicConnection",
            nextToken?: string | null,
          } | null,
          courseId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    subtopics?:  {
      __typename: "ModelSubtopicConnection",
      items:  Array< {
        __typename: "Subtopic",
        id: string,
        name: string,
        releaseStatus: ReleaseStatus,
        userAccessType: UserAccessType,
        topicId: string,
        sortOrder: number,
        topic?:  {
          __typename: "Topic",
          id: string,
          name: string,
          releaseStatus: ReleaseStatus,
          userAccessType: UserAccessType,
          chapterId: string,
          sortOrder: number,
          chapter?:  {
            __typename: "Chapter",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            courseId: string,
            sortOrder: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          subtopics?:  {
            __typename: "ModelSubtopicConnection",
            nextToken?: string | null,
          } | null,
          courseId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        contentIdentifier?: string | null,
        subtopicContent?: string | null,
        courseId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    courseId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTopicsQueryVariables = {
  filter?: ModelTopicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTopicsQuery = {
  listTopics?:  {
    __typename: "ModelTopicConnection",
    items:  Array< {
      __typename: "Topic",
      id: string,
      name: string,
      releaseStatus: ReleaseStatus,
      userAccessType: UserAccessType,
      chapterId: string,
      sortOrder: number,
      chapter?:  {
        __typename: "Chapter",
        id: string,
        name: string,
        releaseStatus: ReleaseStatus,
        userAccessType: UserAccessType,
        courseId: string,
        sortOrder: number,
        course?:  {
          __typename: "Course",
          id: string,
          name: string,
          releaseStatus: ReleaseStatus,
          userAccessType: UserAccessType,
          image?: string | null,
          sortOrder: number,
          chapters?:  {
            __typename: "ModelChapterConnection",
            nextToken?: string | null,
          } | null,
          subscriptionModels?:  {
            __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        topics?:  {
          __typename: "ModelTopicConnection",
          items:  Array< {
            __typename: "Topic",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            chapterId: string,
            sortOrder: number,
            courseId: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      subtopics?:  {
        __typename: "ModelSubtopicConnection",
        items:  Array< {
          __typename: "Subtopic",
          id: string,
          name: string,
          releaseStatus: ReleaseStatus,
          userAccessType: UserAccessType,
          topicId: string,
          sortOrder: number,
          topic?:  {
            __typename: "Topic",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            chapterId: string,
            sortOrder: number,
            courseId: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          contentIdentifier?: string | null,
          subtopicContent?: string | null,
          courseId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      courseId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSubtopicQueryVariables = {
  id: string,
};

export type GetSubtopicQuery = {
  getSubtopic?:  {
    __typename: "Subtopic",
    id: string,
    name: string,
    releaseStatus: ReleaseStatus,
    userAccessType: UserAccessType,
    topicId: string,
    sortOrder: number,
    topic?:  {
      __typename: "Topic",
      id: string,
      name: string,
      releaseStatus: ReleaseStatus,
      userAccessType: UserAccessType,
      chapterId: string,
      sortOrder: number,
      chapter?:  {
        __typename: "Chapter",
        id: string,
        name: string,
        releaseStatus: ReleaseStatus,
        userAccessType: UserAccessType,
        courseId: string,
        sortOrder: number,
        course?:  {
          __typename: "Course",
          id: string,
          name: string,
          releaseStatus: ReleaseStatus,
          userAccessType: UserAccessType,
          image?: string | null,
          sortOrder: number,
          chapters?:  {
            __typename: "ModelChapterConnection",
            nextToken?: string | null,
          } | null,
          subscriptionModels?:  {
            __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        topics?:  {
          __typename: "ModelTopicConnection",
          items:  Array< {
            __typename: "Topic",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            chapterId: string,
            sortOrder: number,
            courseId: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      subtopics?:  {
        __typename: "ModelSubtopicConnection",
        items:  Array< {
          __typename: "Subtopic",
          id: string,
          name: string,
          releaseStatus: ReleaseStatus,
          userAccessType: UserAccessType,
          topicId: string,
          sortOrder: number,
          topic?:  {
            __typename: "Topic",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            chapterId: string,
            sortOrder: number,
            courseId: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          contentIdentifier?: string | null,
          subtopicContent?: string | null,
          courseId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      courseId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    contentIdentifier?: string | null,
    subtopicContent?: string | null,
    courseId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSubtopicsQueryVariables = {
  filter?: ModelSubtopicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSubtopicsQuery = {
  listSubtopics?:  {
    __typename: "ModelSubtopicConnection",
    items:  Array< {
      __typename: "Subtopic",
      id: string,
      name: string,
      releaseStatus: ReleaseStatus,
      userAccessType: UserAccessType,
      topicId: string,
      sortOrder: number,
      topic?:  {
        __typename: "Topic",
        id: string,
        name: string,
        releaseStatus: ReleaseStatus,
        userAccessType: UserAccessType,
        chapterId: string,
        sortOrder: number,
        chapter?:  {
          __typename: "Chapter",
          id: string,
          name: string,
          releaseStatus: ReleaseStatus,
          userAccessType: UserAccessType,
          courseId: string,
          sortOrder: number,
          course?:  {
            __typename: "Course",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            image?: string | null,
            sortOrder: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          topics?:  {
            __typename: "ModelTopicConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        subtopics?:  {
          __typename: "ModelSubtopicConnection",
          items:  Array< {
            __typename: "Subtopic",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            topicId: string,
            sortOrder: number,
            contentIdentifier?: string | null,
            subtopicContent?: string | null,
            courseId: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        courseId: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      contentIdentifier?: string | null,
      subtopicContent?: string | null,
      courseId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  username: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    username: string,
    email: string,
    name: string,
    stripeCustomerId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetSubscriptionModelQueryVariables = {
  id: string,
};

export type GetSubscriptionModelQuery = {
  getSubscriptionModel?:  {
    __typename: "SubscriptionModel",
    id: string,
    amountInLowestDenomination: number,
    currency: Currency,
    isRecurring: boolean,
    timeInterval: TimeInterval,
    stripePriceId: string,
    courseId: string,
    courses?:  {
      __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
      items:  Array< {
        __typename: "CourseSubscriptionModelManyToManyRelation",
        id: string,
        courseId: string,
        subscriptionModelId: string,
        course:  {
          __typename: "Course",
          id: string,
          name: string,
          releaseStatus: ReleaseStatus,
          userAccessType: UserAccessType,
          image?: string | null,
          sortOrder: number,
          chapters?:  {
            __typename: "ModelChapterConnection",
            nextToken?: string | null,
          } | null,
          subscriptionModels?:  {
            __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        subscriptionModel:  {
          __typename: "SubscriptionModel",
          id: string,
          amountInLowestDenomination: number,
          currency: Currency,
          isRecurring: boolean,
          timeInterval: TimeInterval,
          stripePriceId: string,
          courseId: string,
          courses?:  {
            __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetCourseSubscriptionModelManyToManyRelationQueryVariables = {
  id: string,
};

export type GetCourseSubscriptionModelManyToManyRelationQuery = {
  getCourseSubscriptionModelManyToManyRelation?:  {
    __typename: "CourseSubscriptionModelManyToManyRelation",
    id: string,
    courseId: string,
    subscriptionModelId: string,
    course:  {
      __typename: "Course",
      id: string,
      name: string,
      releaseStatus: ReleaseStatus,
      userAccessType: UserAccessType,
      image?: string | null,
      sortOrder: number,
      chapters?:  {
        __typename: "ModelChapterConnection",
        items:  Array< {
          __typename: "Chapter",
          id: string,
          name: string,
          releaseStatus: ReleaseStatus,
          userAccessType: UserAccessType,
          courseId: string,
          sortOrder: number,
          course?:  {
            __typename: "Course",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            image?: string | null,
            sortOrder: number,
            createdAt: string,
            updatedAt: string,
          } | null,
          topics?:  {
            __typename: "ModelTopicConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      subscriptionModels?:  {
        __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
        items:  Array< {
          __typename: "CourseSubscriptionModelManyToManyRelation",
          id: string,
          courseId: string,
          subscriptionModelId: string,
          course:  {
            __typename: "Course",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            image?: string | null,
            sortOrder: number,
            createdAt: string,
            updatedAt: string,
          },
          subscriptionModel:  {
            __typename: "SubscriptionModel",
            id: string,
            amountInLowestDenomination: number,
            currency: Currency,
            isRecurring: boolean,
            timeInterval: TimeInterval,
            stripePriceId: string,
            courseId: string,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    subscriptionModel:  {
      __typename: "SubscriptionModel",
      id: string,
      amountInLowestDenomination: number,
      currency: Currency,
      isRecurring: boolean,
      timeInterval: TimeInterval,
      stripePriceId: string,
      courseId: string,
      courses?:  {
        __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
        items:  Array< {
          __typename: "CourseSubscriptionModelManyToManyRelation",
          id: string,
          courseId: string,
          subscriptionModelId: string,
          course:  {
            __typename: "Course",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            image?: string | null,
            sortOrder: number,
            createdAt: string,
            updatedAt: string,
          },
          subscriptionModel:  {
            __typename: "SubscriptionModel",
            id: string,
            amountInLowestDenomination: number,
            currency: Currency,
            isRecurring: boolean,
            timeInterval: TimeInterval,
            stripePriceId: string,
            courseId: string,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCourseSubscriptionModelManyToManyRelationsQueryVariables = {
  filter?: ModelCourseSubscriptionModelManyToManyRelationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCourseSubscriptionModelManyToManyRelationsQuery = {
  listCourseSubscriptionModelManyToManyRelations?:  {
    __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
    items:  Array< {
      __typename: "CourseSubscriptionModelManyToManyRelation",
      id: string,
      courseId: string,
      subscriptionModelId: string,
      course:  {
        __typename: "Course",
        id: string,
        name: string,
        releaseStatus: ReleaseStatus,
        userAccessType: UserAccessType,
        image?: string | null,
        sortOrder: number,
        chapters?:  {
          __typename: "ModelChapterConnection",
          items:  Array< {
            __typename: "Chapter",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            courseId: string,
            sortOrder: number,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        subscriptionModels?:  {
          __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
          items:  Array< {
            __typename: "CourseSubscriptionModelManyToManyRelation",
            id: string,
            courseId: string,
            subscriptionModelId: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      subscriptionModel:  {
        __typename: "SubscriptionModel",
        id: string,
        amountInLowestDenomination: number,
        currency: Currency,
        isRecurring: boolean,
        timeInterval: TimeInterval,
        stripePriceId: string,
        courseId: string,
        courses?:  {
          __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
          items:  Array< {
            __typename: "CourseSubscriptionModelManyToManyRelation",
            id: string,
            courseId: string,
            subscriptionModelId: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CourseSubscriptionModelManyToManyRelationsByCourseIdQueryVariables = {
  courseId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCourseSubscriptionModelManyToManyRelationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CourseSubscriptionModelManyToManyRelationsByCourseIdQuery = {
  courseSubscriptionModelManyToManyRelationsByCourseId?:  {
    __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
    items:  Array< {
      __typename: "CourseSubscriptionModelManyToManyRelation",
      id: string,
      courseId: string,
      subscriptionModelId: string,
      course:  {
        __typename: "Course",
        id: string,
        name: string,
        releaseStatus: ReleaseStatus,
        userAccessType: UserAccessType,
        image?: string | null,
        sortOrder: number,
        chapters?:  {
          __typename: "ModelChapterConnection",
          items:  Array< {
            __typename: "Chapter",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            courseId: string,
            sortOrder: number,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        subscriptionModels?:  {
          __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
          items:  Array< {
            __typename: "CourseSubscriptionModelManyToManyRelation",
            id: string,
            courseId: string,
            subscriptionModelId: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      subscriptionModel:  {
        __typename: "SubscriptionModel",
        id: string,
        amountInLowestDenomination: number,
        currency: Currency,
        isRecurring: boolean,
        timeInterval: TimeInterval,
        stripePriceId: string,
        courseId: string,
        courses?:  {
          __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
          items:  Array< {
            __typename: "CourseSubscriptionModelManyToManyRelation",
            id: string,
            courseId: string,
            subscriptionModelId: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CourseSubscriptionModelManyToManyRelationsBySubscriptionModelIdQueryVariables = {
  subscriptionModelId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCourseSubscriptionModelManyToManyRelationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CourseSubscriptionModelManyToManyRelationsBySubscriptionModelIdQuery = {
  courseSubscriptionModelManyToManyRelationsBySubscriptionModelId?:  {
    __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
    items:  Array< {
      __typename: "CourseSubscriptionModelManyToManyRelation",
      id: string,
      courseId: string,
      subscriptionModelId: string,
      course:  {
        __typename: "Course",
        id: string,
        name: string,
        releaseStatus: ReleaseStatus,
        userAccessType: UserAccessType,
        image?: string | null,
        sortOrder: number,
        chapters?:  {
          __typename: "ModelChapterConnection",
          items:  Array< {
            __typename: "Chapter",
            id: string,
            name: string,
            releaseStatus: ReleaseStatus,
            userAccessType: UserAccessType,
            courseId: string,
            sortOrder: number,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        subscriptionModels?:  {
          __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
          items:  Array< {
            __typename: "CourseSubscriptionModelManyToManyRelation",
            id: string,
            courseId: string,
            subscriptionModelId: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      subscriptionModel:  {
        __typename: "SubscriptionModel",
        id: string,
        amountInLowestDenomination: number,
        currency: Currency,
        isRecurring: boolean,
        timeInterval: TimeInterval,
        stripePriceId: string,
        courseId: string,
        courses?:  {
          __typename: "ModelCourseSubscriptionModelManyToManyRelationConnection",
          items:  Array< {
            __typename: "CourseSubscriptionModelManyToManyRelation",
            id: string,
            courseId: string,
            subscriptionModelId: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};
