import "./Transactions.css";

import React, { useEffect, useState } from "react";
import { getTransactionsAPI } from "../../api/AccountApi";
import { Custom_Txn } from "../../api/CustomSchemas";
import { getFormattedDate } from "../../utils/MiscUtil";
import { getTxnAmountDisplay } from "../../utils/AccountUtil";
import { DisplayStrings } from "../../utils/DisplayStrings";
import popupLinkIcon from "../../assets/popup-link_icon.svg";
import { NoMoney } from "../no-money/NoMoney";

type TransactionsProps = {};

export const Transactions: React.FunctionComponent<
  TransactionsProps
> = ({}) => {
  const [transactions, setTransactions] = useState<Custom_Txn[] | null>();

  useEffect(() => {
    const body = {};
    getTransactionsAPI(body).then((response) => {
      console.log(response);
      setTransactions(response?.transactions);
    });
  }, []);

  return (
    <div className="txns">
      {transactions && transactions.length > 0 && (
        <>
          <table className="table">
            <thead className="thead">
              <tr className="row">
                <td className="cell">Date</td>
                <td className="cell">Total Amount</td>
                <td className="cell">Payment Method</td>
                <td className="cell">Receipt</td>
              </tr>
            </thead>
            <tbody>
              {transactions.map((item: Custom_Txn) => (
                <tr key={item.id} className="row">
                  <td className="cell">{getFormattedDate(item.created)}</td>
                  <td className="cell">{getTxnAmountDisplay(item)}</td>
                  <td className="cell">{item.payment_method_details.type}</td>
                  <td className="cell">
                    <a href={item.receipt_url} target="_blank">
                      {item.receipt_number ? item.receipt_number : "Receipt"}
                      <img src={popupLinkIcon} alt="Receipt" />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      {transactions && transactions.length == 0 && (
        <NoMoney message={DisplayStrings.NO_RECENT_PAYMENTS} />
      )}
    </div>
  );
};
