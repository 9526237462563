import "./ChapterAccordion.css";

import React, { useEffect, useState } from "react";

import { Chapter, UserAccessType } from "../../api/graphql/schemas";
import { Link } from "react-router-dom";
import chevronBottom from "../../assets/triangle-bottom_icon.svg";
import chevronRight from "../../assets/triangle-right_icon.svg";
import lock_icon from "../../assets/lock_icon.svg";
import unlock_icon from "../../assets/unlock_icon.svg";
import { useSelector } from "react-redux";
import { getDisplayStatus } from "../../utils/CourseUtil";
import { DisplayStatus } from "../../types/DisplayTypes";
import { ComingSoonSubscript } from "../coming-soon-subscript/ComingSoonSubscript";

type ChapterAccordionProps = {
  chapter: Chapter;
  courseId: string;
};

export const ChapterAccordion: React.FunctionComponent<
  ChapterAccordionProps
> = ({ chapter, courseId }) => {
  const [showChapterContents, setShowChapterContents] = useState<boolean>();

  const user = useSelector((state: any) => state.userState.user);
  const selectedTopic = useSelector(
    (state: any) => state.courseState.selectedTopic
  );
  const subscription = useSelector(
    (state: any) => state.accountState.subscriptionForSelectedCourse
  );

  useEffect(() => {
    chapter?.topics?.items?.map((item) => {
      if (item && item.id === selectedTopic.id) {
        setShowChapterContents(true);
      }
    });
  }, [selectedTopic.id]);

  const onChapterClick = () => {
    setShowChapterContents((showChapterContents) => !showChapterContents);
  };

  const subscriptStatus = (item: any) => {
    const displayStatus = getDisplayStatus(item, user, subscription);
    switch (displayStatus) {
      case DisplayStatus.COMING_SOON:
        return <ComingSoonSubscript />;
      case DisplayStatus.HIDE:
        return <></>;
      case DisplayStatus.CONTINUE_AS_USUAL:
        return continueAsUsualCase(item.userAccessType);
      case DisplayStatus.PAYMENT_REQUIRED:
        return <img className="access-icon" src={lock_icon} />;
      case DisplayStatus.SIGN_IN_AND_PAYMENT_REQUIRED:
        return <img className="access-icon" src={lock_icon} />;
      case DisplayStatus.SIGN_IN_REQUIRED:
        return <img className="access-icon" src={lock_icon} />;
    }
  };

  const continueAsUsualCase = (userAccessType: UserAccessType) => {
    if (userAccessType == UserAccessType.GUEST) {
      return <></>;
    } else {
      return <img className="access-icon" src={unlock_icon} />;
    }
  };

  return (
    <>
      {chapter && courseId && (
        <div className="chapter-container">
          <div className="chapter-header" onClick={onChapterClick}>
            {!showChapterContents && <img src={chevronRight} alt="right" />}
            {showChapterContents && <img src={chevronBottom} alt="right" />}
            <span>{chapter.name}</span>
          </div>
          {showChapterContents && (
            <div className="tpc-cntr">
              {chapter.topics?.items?.map(
                (item) =>
                  item && (
                    <Link
                      key={item.id}
                      to={`/course?courseid=${courseId}&topicid=${item.id}`}
                    >
                      <div
                        className={
                          selectedTopic.id === item.id
                            ? "selected tpc-nm"
                            : "notselected tpc-nm"
                        }
                      >
                        <span>{item.name}</span>
                        {subscriptStatus(item)}
                      </div>
                    </Link>
                  )
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};
