import "./AccountPage.css";

import React, { useEffect, useState } from "react";

import { Link, useSearchParams } from "react-router-dom";
import { UpdatePassword } from "../../components/update-password/UpdatePassword";
import { Subscriptions } from "../../components/subscriptions/Subscriptions";
import { Transactions } from "../../components/transactions/Transactions";

type AccountPageProps = {};

export const AccountPage: React.FunctionComponent<AccountPageProps> = ({}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  let sectionParam = searchParams.get("section");

  useEffect(() => {}, []);

  return (
    <div className="account-page">
      <div className="account-nav">
        {/*<Link
          className={sectionParam === "profile" ? "selected" : ""}
          to={`/account?section=profile`}
        >
          <div>Profile</div>
  </Link>*/}
        <Link to={`/account?section=subscriptions`}>
          <div
            className={
              !sectionParam || sectionParam === "subscriptions"
                ? "sectn-nm selected"
                : "sectn-nm notselected"
            }
          >
            Subscriptions
          </div>
        </Link>
        <Link to={`/account?section=payments`}>
          <div
            className={
              sectionParam === "payments"
                ? "sectn-nm selected"
                : "sectn-nm notselected"
            }
          >
            Payments
          </div>
        </Link>
        <Link to={`/account?section=updatepassword`}>
          <div
            className={
              sectionParam === "updatepassword"
                ? "sectn-nm selected"
                : "sectn-nm notselected"
            }
          >
            Update Password
          </div>
        </Link>
      </div>
      <div className="account-main">
        {/*(!sectionParam || sectionParam === "profile") && (
          <div id="profile-main">Profile Stuff</div>
        )*/}
        {(!sectionParam || sectionParam === "subscriptions") && (
          <div id="subscriptions-main">
            <Subscriptions />
          </div>
        )}
        {sectionParam === "payments" && (
          <div id="payments-main">
            <Transactions />
          </div>
        )}
        {sectionParam === "updatepassword" && (
          <div id="updatepassword-main">
            <UpdatePassword />
          </div>
        )}
      </div>
    </div>
  );
};
