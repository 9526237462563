import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getTopicSubtopicAndNoContentAPI } from "../../api/CourseApis";
import { Subtopic } from "../../api/graphql/schemas";
import SubtopicCarousel from "../content-carousel/SubtopicsCarousel";
import "./TopicContentPlayer.css";

type TopicContentPlayerProps = {};

export const TopicContentPlayer: React.FunctionComponent<
  TopicContentPlayerProps
> = ({}) => {
  const [subtopics, setSubtopics] = useState<(Subtopic | null)[] | undefined>(
    []
  );
  const topic = useSelector((state: any) => state.courseState.selectedTopic);

  useEffect(() => {
    // Set subtopic if you get it as part of topic else fetch Topic with Subtopic.
    if (
      topic &&
      topic.subtopics &&
      topic.subtopics.items &&
      topic.subtopics.items.length > 0
    ) {
      setSubtopics(topic.subtopics.items);
    } else if (topic) {
      getTopicSubtopicAndNoContentAPI(topic.id).then(
        (data) => {
          setSubtopics(data?.subtopics?.items);
        },
        (error) => {
          console.log("error: " + error);
        }
      );
    }

    // Cleaning up subtopics.
    return () => {
      setSubtopics(undefined);
    };
  }, [topic.id]);

  return (
    <div className="tpc-cntnt-plyr">
      {subtopics && subtopics.length > 0 && (
        <SubtopicCarousel topicId={topic.id} subtopics={subtopics} />
      )}
    </div>
  );
};
