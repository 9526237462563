import "./UpdatePassword.css";

import { AccountSettings } from "@aws-amplify/ui-react";
import React, { useEffect, useState } from "react";

type UpdatePasswordPageProps = {};

export const UpdatePassword: React.FunctionComponent<
  UpdatePasswordPageProps
> = ({}) => {
  const [message, setMessage] = useState<string | null>(null);
  const passwordUpdateSuccess = () => {
    setMessage("Your password was updated successfully!");
  };
  const passwordUpdateError = () => {
    setMessage("There was an error updating your password!");
  };

  return (
    <div className="update-pwd">
      <AccountSettings.ChangePassword
        onSuccess={passwordUpdateSuccess}
        onError={passwordUpdateError}
      />
      {message && <div>{message}</div>}
    </div>
  );
};
