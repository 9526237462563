export const getCourse_Chapter_Topic_Custom = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      name
      releaseStatus
      userAccessType
      image
      sortOrder
      chapters {
        items {
          id
          name
          releaseStatus
          userAccessType
          courseId
          sortOrder
          topics {
            items {
              id
              name
              releaseStatus
              userAccessType
              chapterId
              sortOrder
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;

export const getCourse_Chapter_Topic_SubscriptionModels_Custom = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      name
      releaseStatus
      userAccessType
      image
      sortOrder
      chapters {
        items {
          id
          name
          releaseStatus
          userAccessType
          courseId
          sortOrder
          topics {
            items {
              id
              name
              releaseStatus
              userAccessType
              chapterId
              sortOrder
              createdAt
              updatedAt
            }
          }
        }
      }
      subscriptionModels {
        items {
          subscriptionModel {
            id
            amountInLowestDenomination
            currency
            isRecurring
            timeInterval
            stripePriceId
          }
        }
      }
    }
  }
`;

export const listCourses_Shallow_Custom = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        releaseStatus
        userAccessType
        image
        sortOrder
      }
    }
  }
`;

export const listCourses_SubscriptionModels_Custom = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        releaseStatus
        userAccessType
        image
        sortOrder
        subscriptionModels {
          items {
            id
            subscriptionModel {
              id
              amountInLowestDenomination
              currency
              isRecurring
              timeInterval
              stripePriceId
              courseId
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export const getTopic_Subtopic_Content_Custom = /* GraphQL */ `
  query GetTopic($id: ID!) {
    getTopic(id: $id) {
      id
      name
      releaseStatus
      userAccessType
      chapterId
      sortOrder
      subtopics {
        items {
          id
          name
          releaseStatus
          userAccessType
          topicId
          sortOrder
          contentIdentifier
          subtopicContent
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const getTopic_Subtopic_NoContent_Custom = /* GraphQL */ `
  query GetTopic($id: ID!) {
    getTopic(id: $id) {
      id
      name
      releaseStatus
      userAccessType
      chapterId
      sortOrder
      subtopics {
        items {
          id
          name
          releaseStatus
          userAccessType
          topicId
          sortOrder
          contentIdentifier
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const getSubtopic_Content_Custom = /* GraphQL */ `
  query GetSubtopic($id: ID!) {
    getSubtopic(id: $id) {
      id
      name
      releaseStatus
      userAccessType
      topicId
      sortOrder
      contentIdentifier
      subtopicContent
    }
  }
`;

export const getUser_Custom = /* GraphQL */ `
  query GetUser($username: ID!) {
    getUser(username: $username) {
      username
      email
      name
      stripeCustomerId
    }
  }
`;
