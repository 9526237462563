import { API, graphqlOperation } from "aws-amplify";
import {
  Chapter,
  Course,
  SubscriptionModel,
  Subtopic,
  Topic,
} from "../../api/graphql/schemas";
import {
  createChapter,
  createCourse,
  createCourseSubscriptionModelManyToManyRelation,
  createSubscriptionModel,
  createSubtopic,
  createTopic,
} from "../../api/graphql/mutations";

import mockCreateCourseData from "./AllCourses.json";

export const private_createMultipleCourseAPI = async (): Promise<boolean> => {
  try {
    let courses = JSON.parse(JSON.stringify(mockCreateCourseData)) as Course[];

    for (let course of courses) {
      const createdCourse = await private_createCourseAPI(course);

      // Creating SubscriptionModels for this course.
      if (course?.subscriptionModels?.items) {
        for (let subscriptionModelItem of course?.subscriptionModels?.items) {
          console.log("Creating SubscriptionModel");

          let subModel = subscriptionModelItem?.subscriptionModel;
          let subscriptionModelInput = {
            input: {
              amountInLowestDenomination: subModel?.amountInLowestDenomination,
              currency: subModel?.currency,
              isRecurring: subModel?.isRecurring,
              timeInterval: subModel?.timeInterval,
              stripePriceId: subModel?.stripePriceId,
              courseId: createdCourse.id,
            },
          };
          type subscriptionModelResult = {
            data: {
              createSubscriptionModel: SubscriptionModel;
            };
          };

          const graphqlResult = await API.graphql(
            graphqlOperation(createSubscriptionModel, subscriptionModelInput)
          );

          // Return value from GraphQl Query.
          let createdSubscriptionModel = (
            graphqlResult as subscriptionModelResult
          )?.data?.createSubscriptionModel as SubscriptionModel;

          console.log("Created SubscriptionModel");

          console.log("Created SubscriptionModel and Course Relation");
          let subscriptionModelCourseRelationInput = {
            input: {
              courseId: createdCourse.id,
              subscriptionModelId: createdSubscriptionModel.id,
              //course: createCourse,
              //subscriptionModel: createdSubscriptionModel,
            },
          };
          type subscriptionModelCourseRelationResult = {
            data: {
              createCourseSubscriptionModelManyToManyRelation: SubscriptionModel;
            };
          };

          await API.graphql(
            graphqlOperation(
              createCourseSubscriptionModelManyToManyRelation,
              subscriptionModelCourseRelationInput
            )
          );

          console.log("Created SubscriptionModel and Course Relation");
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
  return true;
};

const private_createCourseAPI = async (course: Course) => {
  // GraphQl Query Input Type.
  let input = {
    input: {
      name: course.name,
      releaseStatus: course.releaseStatus,
      userAccessType: course.userAccessType,
      sortOrder: course.sortOrder,
      image: course.image,
    },
  };

  // GraphQl Query Result Type.
  type Result = {
    data: {
      createCourse: Course;
    };
  };

  console.log("Creating Course");
  // Makng the Graphql Query.
  const graphqlResult = await API.graphql(
    graphqlOperation(createCourse, input)
  );
  console.log("Created Course");

  // Return value from GraphQl Query.
  let createdCourse = (graphqlResult as Result)?.data?.createCourse as Course;

  // Creating Chapter inside the course.
  if (
    course &&
    course.chapters &&
    course.chapters.items &&
    course.chapters.items.length > 0
  ) {
    let chapterIndex = 0;
    for (let chapterItem of course.chapters.items) {
      if (chapterItem) {
        await private_createChapterAPI(chapterItem, createdCourse);
      }
      chapterIndex++;
    }
  }

  return createdCourse;
};

const private_createChapterAPI = async (
  chapter: Chapter,
  createdCourse: Course
) => {
  // GraphQl Query Input Type
  let input = {
    input: {
      name: chapter.name,
      releaseStatus: chapter.releaseStatus,
      userAccessType: chapter.userAccessType,
      courseId: createdCourse.id,
      sortOrder: chapter.sortOrder,
    },
  };

  // GraphQl Query Result Type
  type Result = {
    data: {
      createChapter: Chapter;
    };
  };

  console.log("Creating Chapter");
  // Making Graphql Query.
  const graphqlResult = await API.graphql(
    graphqlOperation(createChapter, input)
  );
  console.log("Created Chapter");

  let createdChapter = (graphqlResult as Result)?.data
    ?.createChapter as Chapter;

  if (
    chapter.topics &&
    chapter.topics.items &&
    chapter.topics.items.length > 0
  ) {
    let topicIndex = 0;
    for (let topicItem of chapter.topics.items) {
      if (topicItem) {
        await private_createTopicAPI(topicItem, createdChapter);
      }
      topicIndex++;
    }
  }

  return true;
};

const private_createTopicAPI = async (
  topic: Topic,
  createdChapter: Chapter
) => {
  // GraphQl Query Input Type
  let input = {
    input: {
      name: topic.name,
      releaseStatus: topic.releaseStatus,
      userAccessType: topic.userAccessType,
      chapterId: createdChapter.id,
      sortOrder: topic.sortOrder,
      courseId: createdChapter.courseId,
    },
  };

  // GraphQl Query Result Type
  type Result = {
    data: {
      createTopic: Topic;
    };
  };

  console.log("Creating Topic");
  // Making Graphql Query.
  const graphqlResult = await API.graphql(graphqlOperation(createTopic, input));
  console.log("Created Topic");

  let createdTopic = (graphqlResult as Result)?.data?.createTopic as Topic;

  if (
    topic &&
    topic.subtopics &&
    topic.subtopics.items &&
    topic.subtopics.items.length > 0
  ) {
    let subtopicIndex = 0;
    for (let subtopicItem of topic.subtopics.items) {
      if (subtopicItem) {
        await private_createSubtopicAPI(subtopicItem, createdTopic);
      }
      subtopicIndex++;
    }
  }

  return true;
};

const private_createSubtopicAPI = async (
  subtopic: Subtopic,
  createdTopic: Topic
) => {
  // GraphQl Query Input Type
  let input = {
    input: {
      name: subtopic.name,
      releaseStatus: subtopic.releaseStatus,
      userAccessType: subtopic.userAccessType,
      topicId: createdTopic.id,
      contentIdentifier: subtopic.contentIdentifier,
      sortOrder: subtopic.sortOrder,
      courseId: createdTopic.courseId,
    },
  };

  // GraphQl Query Result Type
  type Result = {
    data: {
      createSubtopic: Subtopic;
    };
  };

  console.log("Creating Subtopic");
  await API.graphql(graphqlOperation(createSubtopic, input));
  console.log("Created Subtopic");

  return true;
};
