import { AnyAction } from "redux";
import { Course, Topic } from "../api/graphql/schemas";

export type CourseState = {
  selectedCourse?: Course;
  selectedTopic?: Topic;
};

const initialCourseState: CourseState = {
  selectedCourse: undefined,
  selectedTopic: undefined,
};

export enum CourseReducerActionTypes {
  SET_SELECTED_COURSE = "SET_SELECTED_COURSE",
  SET_SELECTED_TOPIC = "SET_SELECTED_TOPIC",
}

export const courseReducer = (
  state = initialCourseState,
  action: AnyAction
) => {
  const { type, payload } = action;
  switch (type) {
    case CourseReducerActionTypes.SET_SELECTED_COURSE:
      return { ...state, selectedCourse: payload };
    case CourseReducerActionTypes.SET_SELECTED_TOPIC:
      return { ...state, selectedTopic: payload };
  }

  return state;
};
