import "./Subscriptions.css";

import React, { useEffect, useState } from "react";
import { Course } from "../../api/graphql/schemas";
import { getAllCoursesSubscriptionModelsAPI } from "../../api/CourseApis";
import { courseComparator } from "../../utils/CourseUtil";
import { useSelector } from "react-redux";
import { SubscriptionBox } from "../subscription-box/SubscriptionBox";
import {
  getSubscriptionAndModelForCourse,
  getSubscriptionForCourse,
} from "../../utils/AccountUtil";
import { DisplayStrings } from "../../utils/DisplayStrings";
import { NoMoney } from "../no-money/NoMoney";

type SubscriptionsProps = {};

export const Subscriptions: React.FunctionComponent<
  SubscriptionsProps
> = ({}) => {
  // Getting values from Store
  const allSubscriptions = useSelector(
    (state: any) => state.accountState.allSubscriptions
  );

  const [allCourses, setAllCourses] = useState<Course[] | undefined>([]);

  useEffect(() => {
    getAllCoursesSubscriptionModelsAPI().then(
      (data) => {
        data?.sort((course1, course2) => courseComparator(course1, course2));
        console.log("Called Inside");
        setAllCourses(data);
      },
      (error) => {
        // console.log("error: " + error);
      }
    );
  }, []);

  return (
    <>
      {allCourses &&
        (allSubscriptions === null ||
          (allSubscriptions !== undefined &&
            allSubscriptions.length === 0)) && (
          <NoMoney message={DisplayStrings.NO_ACTIVE_SUBSCRIPTIONS} />
        )}
      {allCourses && allSubscriptions && allSubscriptions.length > 0 && (
        <div className="sub-boxes-container">
          {allCourses.map((course) => {
            const subscriptionAndModel = getSubscriptionAndModelForCourse(
              course,
              allSubscriptions
            );
            return (
              subscriptionAndModel && (
                <SubscriptionBox
                  key={course.id}
                  course={course}
                  subscriptionAndModel={subscriptionAndModel}
                />
              )
            );
          })}
        </div>
      )}
    </>
  );
};
