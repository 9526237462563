import React from "react";
import "./ComingSoonSubscript.css";

type ComingSoonSubscriptProps = {};

export const ComingSoonSubscript: React.FunctionComponent<
  ComingSoonSubscriptProps
> = ({}) => {
  return <div className="coming-soon-sbsc">Coming Soon</div>;
};
