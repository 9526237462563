import "./Carousel.css";

import { FunctionComponent, useEffect, useState } from "react";

import { Subtopic } from "../../api/graphql/schemas";
import { SubtopicItem } from "./SubtopicItem";
import { Link, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import leftArrowIcon from "../../assets/chevron-arrow-left_white_icon.svg";
import rightArrowIcon from "../../assets/chevron-arrow-right_white_icon.svg";
import { DisplayStrings } from "../../utils/DisplayStrings";

type SubtopicCarouselProps = {
  subtopics: (Subtopic | null)[] | undefined;
  topicId: string;
};

const SubtopicCarousel: FunctionComponent<SubtopicCarouselProps> = ({
  subtopics,
  topicId,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  let indexParam = searchParams.get("index");

  // Getting values from store.
  const course = useSelector((state: any) => state.courseState.selectedCourse);

  const getValidIndex = (indexArg: any) => {
    let index = indexArg ? parseInt(indexArg) : 0;

    if (!index || index < 0 || !subtopics || subtopics.length == 0) {
      return 0;
    } else if (index === subtopics.length || index > subtopics.length) {
      return subtopics.length - 1;
    } else {
      return index;
    }
  };

  let validIndex = getValidIndex(indexParam);
  const [activeIndex, setActiveIndex] = useState(validIndex);

  useEffect(() => {
    // Only set index if it changes.
    if (activeIndex !== validIndex) {
      setActiveIndex(validIndex);
    }
  }, [validIndex]);

  const isIndexValid = (indexArg: number) => {
    return (
      subtopics &&
      subtopics.length > 0 &&
      indexArg < subtopics?.length &&
      indexArg > -1
    );
  };

  // TODO {...handlers} for swiping.
  return (
    <>
      {subtopics && (
        <div className="carousel">
          <div className="flippers">
            {isIndexValid(activeIndex - 1) ? (
              <Link
                title="previous"
                key="previous"
                to={`/course?courseid=${
                  course.id
                }&topicid=${topicId}&index=${getValidIndex(activeIndex - 1)}`}
              >
                <div className="flipper previous">
                  <img src={leftArrowIcon} />
                </div>
              </Link>
            ) : (
              <div className="flipper previous"></div>
            )}
            <div className="flpr-txt">
              {activeIndex + 1}
              {DisplayStrings.SPACE}/{DisplayStrings.SPACE}
              {subtopics?.length}
            </div>
            {isIndexValid(activeIndex + 1) ? (
              <Link
                title="next"
                key="next"
                to={`/course?courseid=${
                  course.id
                }&topicid=${topicId}&index=${getValidIndex(activeIndex + 1)}`}
              >
                <div className="flipper next">
                  <img src={rightArrowIcon} />
                </div>
              </Link>
            ) : (
              <div className="flipper next"></div>
            )}
          </div>
          {subtopics && isIndexValid(activeIndex) && subtopics[activeIndex] && (
            <SubtopicItem subtopic={subtopics[activeIndex]!}></SubtopicItem>
          )}
        </div>
      )}
    </>
  );
};

export default SubtopicCarousel;
