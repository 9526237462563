import { AnyAction } from "redux";
import { Custom_Subscription } from "../api/CustomSchemas";

export type AccountState = {
  /*
    subscriptions/subscription: Can have 3 different values
    1. undefined: Means nothing has been fetched from server, either we did not make a request or user is not authenticated so we did not make a fetch for subscriptions/subscription.
    Note: When user is not signed in, we don't make fetch subscriptions and state stays "undefined".
    2. null: We made a request to fetch but there were subscriptions/subscription. Same applies for subscriptionforselectcourse
    3. Actual Value.
  */
  allSubscriptions: Custom_Subscription[] | null | undefined;
  subscriptionForSelectedCourse?: Custom_Subscription | null | undefined;
};

const initialAccountState: AccountState = {
  allSubscriptions: undefined,
  subscriptionForSelectedCourse: undefined,
};

export enum AccountReducerActionTypes {
  SET_ALL_SUBSCRIPTIONS = "SET_ALL_SUBSCRIPTIONS",
  SET_SUBSCRIPTION_FOR_SELECTED_COURSE = "SET_SUBSCRIPTIONS",
}

export const accountReducer = (
  state = initialAccountState,
  action: AnyAction
) => {
  const { type, payload } = action;
  switch (type) {
    case AccountReducerActionTypes.SET_ALL_SUBSCRIPTIONS:
      return { ...state, allSubscriptions: payload };
    case AccountReducerActionTypes.SET_SUBSCRIPTION_FOR_SELECTED_COURSE:
      return { ...state, subscriptionForSelectedCourse: payload };
  }

  return state;
};
