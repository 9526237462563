export const DisplayStrings = {
  COMING_SOON: "Coming Soon",
  SIGN_IN_REQUIRED: "Please sign in to continue.",
  PAYMENT_REQUIRED:
    "Access to this content requires payment. Please subscribe to this course to continue.",
  SIGN_IN_AND_PAYMENT_REQUIRED:
    "Access to this content requires payment. Please Sign in to continue to checkout.",
  SUBSCRIPTION_MODEL_MONTHLY_MEMBERSHIP: "Monthly Membership",
  SUBSCRIPTION_MODEL_QUARTERLY_MEMBERSHIP: "Quarterly Membership",

  SUBSCRIPTION_MODEL_ONCE_MONTH_MEMBERSHIP: "Access for 1 Month",
  SUBSCRIPTION_MODEL_ONCE_QUARTER_MEMBERSHIP: "Access for 1 Quarter",

  SUBSCRIPTION_MODEL_MONTHLY_PRICE_DENOMINATION: "Month",
  SUBSCRIPTION_MODEL_QUARTERLY_PRICE_DENOMINATION: "Quarter",

  CURRENCY_USD: "USD",

  SPACE: " ",

  NO_ACTIVE_SUBSCRIPTIONS: "No courses subscribed.",
  NO_RECENT_PAYMENTS: "No recent payments.",
  CANCEL_SUBSCRIPTION_BUTTON_TEXT: "Cancel Subscription",
  RENEW_SUBSCRIPTION_BUTTON_TEXT: "Renew Subscription",
  RECENT_PAYMENTS_TEXT: "Latest payments",
};
