import "./CheckoutModel.css";

import React, { useEffect, useState } from "react";
import closeIcon from "../../assets/close_icon.svg";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../components/payments/CheckoutForm";
import { subscribeAPI } from "../../api/AccountApi";
import { SubscriptionModel } from "../../api/graphql/schemas";
import { getFullImagePath } from "../../utils/ImageUtil";
import { useSelector } from "react-redux";
import {
  getSubscriptionModelPriceDisplay,
  getTimeSpanMembershipDisplay,
} from "../../utils/AccountUtil";
import { SweamersConfig } from "../../config/SweamerConfig";

const stripePromise = loadStripe(SweamersConfig.STRIPE_PUBLISHABLE_KEY);

const themeType: "stripe" | "night" | "flat" | "none" | undefined = "stripe";

type CheckoutModelProps = {
  subModel: SubscriptionModel;
  onDismissCheckoutModel?: () => void;
};

export const CheckoutModel: React.FunctionComponent<CheckoutModelProps> = ({
  onDismissCheckoutModel,
  subModel,
}) => {
  const [clientSecret, setClientSecret] = useState("");
  const course = useSelector((state: any) => state.courseState.selectedCourse);

  useEffect(() => {
    !clientSecret && course.id && handlePay();
  }, [course.id]);

  const handlePay = async () => {
    const body = {
      subscriptionModel: subModel.id,
    };

    // Make the request
    let data = await subscribeAPI(body);
    setClientSecret(data.clientSecret);
  };

  const appearance = {
    theme: themeType,
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {clientSecret && (
        <div className="checkout-model">
          <div className="model-bg">
            <div className="dismiss-button" onClick={onDismissCheckoutModel}>
              <img src={closeIcon} alt="dismiss" />
            </div>
            <div className="checkout-form-container">
              <div className="crs-checkout">
                <div className="crs-plan">
                  <img src={getFullImagePath(course.image)} />
                  <div className="plan-crs-name">{course.name}</div>
                </div>
                <div className="pyment-plan">
                  <div className="title">
                    {getTimeSpanMembershipDisplay(subModel)}
                  </div>
                  <div className="price">
                    {getSubscriptionModelPriceDisplay(subModel)}
                  </div>
                </div>
              </div>
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm />
              </Elements>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
