import { configureStore } from "@reduxjs/toolkit";
import { accountReducer } from "./account.Reducer";
import { courseReducer } from "./course.Reducer";
import { userReducer } from "./user.Reducer";

export const store = configureStore({
  reducer: {
    userState: userReducer,
    accountState: accountReducer,
    courseState: courseReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
