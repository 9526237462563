import "./ImagePlayer.css";

import React, { useEffect, useState, useCallback } from "react";
import arrowLeft from "../../assets/chevron-arrow-left_white_icon.svg";
import arrowRight from "../../assets/chevron-arrow-right_white_icon.svg";
import pauseIcon from "../../assets/pause_white_icon.svg";
import playIcon from "../../assets/triangle-right_white_icon.svg";

type ImagePlayerProps = {
  images: string[];
};
let intervalId: NodeJS.Timeout;
const intervalTime = 1000;

export const IMAGE_PLAYER_TAG = "image-player";
export const IMAGE_PLAYER_ATTRIBUTE = "images";

/* Sample Image tag from backend.
<image-player images='[ "https://static.scientificamerican.com/sciam/cache/file/7A715AD8-449D-4B5A-ABA2C5D92D9B5A21_source.png", "https://www.allaboutbirds.org/news/wp-content/uploads/2020/07/STanager-Shapiro-ML.jpg","https://images.pexels.com/photos/1661179/pexels-photo-1661179.jpeg"]'></image-player>
*/
export const ImagePlayer: React.FunctionComponent<ImagePlayerProps> = ({
  images,
}) => {
  const [isPlayState, setIsPlayState] = useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

  const incrementIndex = (isIncrement: boolean) => {
    if (isIncrement) {
      setCurrentImageIndex((index) => ensureCorrectIndex(index + 1));
    } else {
      setCurrentImageIndex((index) => ensureCorrectIndex(index - 1));
    }
  };

  const ensureCorrectIndex = (index: number) => {
    if (index < 0) {
      index = images.length - 1;
    } else if (index > images.length - 1) {
      index = 0;
    }
    return index;
  };

  const playPauseCallback = useCallback(() => {
    incrementIndex(true);
  }, []);

  const onPlayPauseClick = (isPlaying: boolean) => {
    setIsPlayState(isPlaying);
    if (isPlaying) {
      intervalId = setInterval(playPauseCallback, intervalTime);
    } else {
      clearInterval(intervalId);
    }
  };

  useEffect(() => {
    return () => clearInterval(intervalId);
  }, [images]);

  return (
    <>
      {images && images.length > 0 && (
        <div className="img-plyr">
          <div className="dsply-img">
            <img
              alt="Current"
              className="cur-img"
              src={images[currentImageIndex]}
            />
          </div>
          <div className="plyr-cntrl">
            <div className="mdl-cntl">
              <img
                alt="left"
                className="arrow left-arrow"
                onClick={() => incrementIndex(false)}
                src={arrowLeft}
              />
              {isPlayState ? (
                <img
                  alt="pause"
                  className="play-pause"
                  onClick={() => onPlayPauseClick(false)}
                  src={pauseIcon}
                />
              ) : (
                <img
                  alt="play"
                  className="play-pause"
                  onClick={() => onPlayPauseClick(true)}
                  src={playIcon}
                />
              )}
              <img
                alt="right"
                className="arrow right-arrow"
                onClick={() => incrementIndex(true)}
                src={arrowRight}
              />
            </div>
            <div className="index">
              {currentImageIndex + 1} / {images.length}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
