export const listCourses_Prices_Admin_Custom = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        releaseStatus
        userAccessType
        image
        sortOrder
        subscriptionModels {
          items {
            id
            courseId
            subscriptionModelId
            subscriptionModel {
              id
              amountInLowestDenomination
              currency
              isRecurring
              timeInterval
              stripePriceId
            }
          }
        }
      }
    }
  }
`;
