import React, { FunctionComponent, useEffect, useState } from "react";

// !DO NOT CHANGE ORDER OF PRISM FILES: Keep Prism related files together in order below.
import Prism from "prismjs";
import "prismjs/components/prism-java";
import "prismjs/plugins/line-numbers/prism-line-numbers.js";
import "prismjs/plugins/line-numbers/prism-line-numbers.css";
import "../../assets/code-theme-sweamers.css";
import { createRoot } from "react-dom/client";

import { Subtopic } from "../../api/graphql/schemas";
import { getDisplayStatus } from "../../utils/CourseUtil";
import { DisplayStatus } from "../../types/DisplayTypes";
import { DisplayStrings } from "../../utils/DisplayStrings";
import { useSelector } from "react-redux";
import { ComingSoon } from "../coming-soon/ComingSoon";
import { PaymentRequired } from "../payment-required/PaymentRequired";
import { SignnRequired } from "../signin-required/SigninRequired";
import { ImagePlayer } from "../image-player/ImagePlayer";
import { getSubtopicAndContentAPI } from "../../api/CourseApis";

type SubtopicItemProps = {
  subtopic: Subtopic;
};

export const SubtopicItem: FunctionComponent<SubtopicItemProps> = ({
  subtopic,
}) => {
  const [content, setContent] = useState<string | null>();

  const user = useSelector((state: any) => state.userState.user);
  const subscriptionForThisCourse = useSelector(
    (state: any) => state.accountState.subscriptionForSelectedCourse
  );

  const displayStatus: DisplayStatus = getDisplayStatus(
    subtopic,
    user,
    subscriptionForThisCourse
  );

  useEffect(() => {
    if (subtopic && displayStatus === DisplayStatus.CONTINUE_AS_USUAL) {
      if (subtopic.subtopicContent) {
        setContent(subtopic.subtopicContent);
      } else {
        getSubtopicAndContentAPI(subtopic.id).then(
          (data) => {
            setContent(data?.subtopicContent);
          },
          (error) => {
            console.log("error: " + error);
          }
        );
      }
    }

    // Cleaning up subtopic content.
    return () => {
      setContent(undefined);
    };
  }, [subtopic?.id, displayStatus]);

  useEffect(() => {
    if (content) {
      //const codeBlock = document.querySelector(".language-java");
      Prism.highlightAll();
    }
  });

  const contentRef = React.createRef<HTMLDivElement>();

  /*
   * If we put dependency as empty array [] then it does not work.
   * Here is reproduction:
   * Step 1: Click on a topic/subtopic that has image player.
   * Step 2: Click on something else.
   * Step 3: Click on a topic/subtopic that has image player: Image does not show up.
   */
  useEffect(() => {
    if (content) {
      const imagePlayerElements: NodeListOf<HTMLElement> =
        contentRef.current?.querySelectorAll("image-player")!;
      if (imagePlayerElements) {
        const imagePlayerElementsArray: HTMLElement[] =
          Array.from(imagePlayerElements);

        imagePlayerElementsArray?.map((htmlElem) => {
          let imagesArrString = htmlElem.getAttribute("images");
          const imagesArr = imagesArrString && JSON.parse(imagesArrString);

          const root = createRoot(htmlElem);
          root.render(<ImagePlayer images={imagesArr} />);
        });
      }
    }
  });

  const display = (displayStatus: DisplayStatus) => {
    switch (displayStatus) {
      case DisplayStatus.COMING_SOON:
        return <ComingSoon />;
      case DisplayStatus.HIDE:
        return <></>;
      case DisplayStatus.PAYMENT_REQUIRED:
        return <PaymentRequired />;
      case DisplayStatus.SIGN_IN_AND_PAYMENT_REQUIRED:
        return (
          <SignnRequired
            message={DisplayStrings.SIGN_IN_AND_PAYMENT_REQUIRED}
          />
        );
      case DisplayStatus.SIGN_IN_REQUIRED:
        return <SignnRequired message={DisplayStrings.SIGN_IN_REQUIRED} />;
    }
  };

  const subtopicDisplayDecision = (
    content: string | null | undefined,
    displayStatus: DisplayStatus
  ) => {
    if (displayStatus === DisplayStatus.CONTINUE_AS_USUAL && content) {
      return (
        <div
          className="carousel-item"
          ref={contentRef}
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      );
    } else {
      return <div className="carousel-item">{display(displayStatus)}</div>;
    }
  };

  return <>{subtopic && subtopicDisplayDecision(content, displayStatus)}</>;
};
