/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      name
      releaseStatus
      userAccessType
      image
      sortOrder
      chapters {
        items {
          id
          name
          releaseStatus
          userAccessType
          courseId
          sortOrder
          course {
            id
            name
            releaseStatus
            userAccessType
            image
            sortOrder
            chapters {
              nextToken
            }
            subscriptionModels {
              nextToken
            }
            createdAt
            updatedAt
          }
          topics {
            items {
              id
              name
              releaseStatus
              userAccessType
              chapterId
              sortOrder
              courseId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      subscriptionModels {
        items {
          id
          courseId
          subscriptionModelId
          course {
            id
            name
            releaseStatus
            userAccessType
            image
            sortOrder
            chapters {
              nextToken
            }
            subscriptionModels {
              nextToken
            }
            createdAt
            updatedAt
          }
          subscriptionModel {
            id
            amountInLowestDenomination
            currency
            isRecurring
            timeInterval
            stripePriceId
            courseId
            courses {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createChapter = /* GraphQL */ `
  mutation CreateChapter(
    $input: CreateChapterInput!
    $condition: ModelChapterConditionInput
  ) {
    createChapter(input: $input, condition: $condition) {
      id
      name
      releaseStatus
      userAccessType
      courseId
      sortOrder
      course {
        id
        name
        releaseStatus
        userAccessType
        image
        sortOrder
        chapters {
          items {
            id
            name
            releaseStatus
            userAccessType
            courseId
            sortOrder
            course {
              id
              name
              releaseStatus
              userAccessType
              image
              sortOrder
              createdAt
              updatedAt
            }
            topics {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        subscriptionModels {
          items {
            id
            courseId
            subscriptionModelId
            course {
              id
              name
              releaseStatus
              userAccessType
              image
              sortOrder
              createdAt
              updatedAt
            }
            subscriptionModel {
              id
              amountInLowestDenomination
              currency
              isRecurring
              timeInterval
              stripePriceId
              courseId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      topics {
        items {
          id
          name
          releaseStatus
          userAccessType
          chapterId
          sortOrder
          chapter {
            id
            name
            releaseStatus
            userAccessType
            courseId
            sortOrder
            course {
              id
              name
              releaseStatus
              userAccessType
              image
              sortOrder
              createdAt
              updatedAt
            }
            topics {
              nextToken
            }
            createdAt
            updatedAt
          }
          subtopics {
            items {
              id
              name
              releaseStatus
              userAccessType
              topicId
              sortOrder
              contentIdentifier
              subtopicContent
              courseId
              createdAt
              updatedAt
            }
            nextToken
          }
          courseId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTopic = /* GraphQL */ `
  mutation CreateTopic(
    $input: CreateTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    createTopic(input: $input, condition: $condition) {
      id
      name
      releaseStatus
      userAccessType
      chapterId
      sortOrder
      chapter {
        id
        name
        releaseStatus
        userAccessType
        courseId
        sortOrder
        course {
          id
          name
          releaseStatus
          userAccessType
          image
          sortOrder
          chapters {
            items {
              id
              name
              releaseStatus
              userAccessType
              courseId
              sortOrder
              createdAt
              updatedAt
            }
            nextToken
          }
          subscriptionModels {
            items {
              id
              courseId
              subscriptionModelId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        topics {
          items {
            id
            name
            releaseStatus
            userAccessType
            chapterId
            sortOrder
            chapter {
              id
              name
              releaseStatus
              userAccessType
              courseId
              sortOrder
              createdAt
              updatedAt
            }
            subtopics {
              nextToken
            }
            courseId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      subtopics {
        items {
          id
          name
          releaseStatus
          userAccessType
          topicId
          sortOrder
          topic {
            id
            name
            releaseStatus
            userAccessType
            chapterId
            sortOrder
            chapter {
              id
              name
              releaseStatus
              userAccessType
              courseId
              sortOrder
              createdAt
              updatedAt
            }
            subtopics {
              nextToken
            }
            courseId
            createdAt
            updatedAt
          }
          contentIdentifier
          subtopicContent
          courseId
          createdAt
          updatedAt
        }
        nextToken
      }
      courseId
      createdAt
      updatedAt
    }
  }
`;
export const createSubtopic = /* GraphQL */ `
  mutation CreateSubtopic(
    $input: CreateSubtopicInput!
    $condition: ModelSubtopicConditionInput
  ) {
    createSubtopic(input: $input, condition: $condition) {
      id
      name
      releaseStatus
      userAccessType
      topicId
      sortOrder
      topic {
        id
        name
        releaseStatus
        userAccessType
        chapterId
        sortOrder
        chapter {
          id
          name
          releaseStatus
          userAccessType
          courseId
          sortOrder
          course {
            id
            name
            releaseStatus
            userAccessType
            image
            sortOrder
            chapters {
              nextToken
            }
            subscriptionModels {
              nextToken
            }
            createdAt
            updatedAt
          }
          topics {
            items {
              id
              name
              releaseStatus
              userAccessType
              chapterId
              sortOrder
              courseId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        subtopics {
          items {
            id
            name
            releaseStatus
            userAccessType
            topicId
            sortOrder
            topic {
              id
              name
              releaseStatus
              userAccessType
              chapterId
              sortOrder
              courseId
              createdAt
              updatedAt
            }
            contentIdentifier
            subtopicContent
            courseId
            createdAt
            updatedAt
          }
          nextToken
        }
        courseId
        createdAt
        updatedAt
      }
      contentIdentifier
      subtopicContent
      courseId
      createdAt
      updatedAt
    }
  }
`;
export const createSubscriptionModel = /* GraphQL */ `
  mutation CreateSubscriptionModel(
    $input: CreateSubscriptionModelInput!
    $condition: ModelSubscriptionModelConditionInput
  ) {
    createSubscriptionModel(input: $input, condition: $condition) {
      id
      amountInLowestDenomination
      currency
      isRecurring
      timeInterval
      stripePriceId
      courseId
      courses {
        items {
          id
          courseId
          subscriptionModelId
          course {
            id
            name
            releaseStatus
            userAccessType
            image
            sortOrder
            chapters {
              nextToken
            }
            subscriptionModels {
              nextToken
            }
            createdAt
            updatedAt
          }
          subscriptionModel {
            id
            amountInLowestDenomination
            currency
            isRecurring
            timeInterval
            stripePriceId
            courseId
            courses {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSubscriptionModel = /* GraphQL */ `
  mutation UpdateSubscriptionModel(
    $input: UpdateSubscriptionModelInput!
    $condition: ModelSubscriptionModelConditionInput
  ) {
    updateSubscriptionModel(input: $input, condition: $condition) {
      id
      amountInLowestDenomination
      currency
      isRecurring
      timeInterval
      stripePriceId
      courseId
      courses {
        items {
          id
          courseId
          subscriptionModelId
          course {
            id
            name
            releaseStatus
            userAccessType
            image
            sortOrder
            chapters {
              nextToken
            }
            subscriptionModels {
              nextToken
            }
            createdAt
            updatedAt
          }
          subscriptionModel {
            id
            amountInLowestDenomination
            currency
            isRecurring
            timeInterval
            stripePriceId
            courseId
            courses {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCourseSubscriptionModelManyToManyRelation = /* GraphQL */ `
  mutation CreateCourseSubscriptionModelManyToManyRelation(
    $input: CreateCourseSubscriptionModelManyToManyRelationInput!
    $condition: ModelCourseSubscriptionModelManyToManyRelationConditionInput
  ) {
    createCourseSubscriptionModelManyToManyRelation(
      input: $input
      condition: $condition
    ) {
      id
      courseId
      subscriptionModelId
      course {
        id
        name
        releaseStatus
        userAccessType
        image
        sortOrder
        chapters {
          items {
            id
            name
            releaseStatus
            userAccessType
            courseId
            sortOrder
            course {
              id
              name
              releaseStatus
              userAccessType
              image
              sortOrder
              createdAt
              updatedAt
            }
            topics {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        subscriptionModels {
          items {
            id
            courseId
            subscriptionModelId
            course {
              id
              name
              releaseStatus
              userAccessType
              image
              sortOrder
              createdAt
              updatedAt
            }
            subscriptionModel {
              id
              amountInLowestDenomination
              currency
              isRecurring
              timeInterval
              stripePriceId
              courseId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      subscriptionModel {
        id
        amountInLowestDenomination
        currency
        isRecurring
        timeInterval
        stripePriceId
        courseId
        courses {
          items {
            id
            courseId
            subscriptionModelId
            course {
              id
              name
              releaseStatus
              userAccessType
              image
              sortOrder
              createdAt
              updatedAt
            }
            subscriptionModel {
              id
              amountInLowestDenomination
              currency
              isRecurring
              timeInterval
              stripePriceId
              courseId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCourseSubscriptionModelManyToManyRelation = /* GraphQL */ `
  mutation UpdateCourseSubscriptionModelManyToManyRelation(
    $input: UpdateCourseSubscriptionModelManyToManyRelationInput!
    $condition: ModelCourseSubscriptionModelManyToManyRelationConditionInput
  ) {
    updateCourseSubscriptionModelManyToManyRelation(
      input: $input
      condition: $condition
    ) {
      id
      courseId
      subscriptionModelId
      course {
        id
        name
        releaseStatus
        userAccessType
        image
        sortOrder
        chapters {
          items {
            id
            name
            releaseStatus
            userAccessType
            courseId
            sortOrder
            course {
              id
              name
              releaseStatus
              userAccessType
              image
              sortOrder
              createdAt
              updatedAt
            }
            topics {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        subscriptionModels {
          items {
            id
            courseId
            subscriptionModelId
            course {
              id
              name
              releaseStatus
              userAccessType
              image
              sortOrder
              createdAt
              updatedAt
            }
            subscriptionModel {
              id
              amountInLowestDenomination
              currency
              isRecurring
              timeInterval
              stripePriceId
              courseId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      subscriptionModel {
        id
        amountInLowestDenomination
        currency
        isRecurring
        timeInterval
        stripePriceId
        courseId
        courses {
          items {
            id
            courseId
            subscriptionModelId
            course {
              id
              name
              releaseStatus
              userAccessType
              image
              sortOrder
              createdAt
              updatedAt
            }
            subscriptionModel {
              id
              amountInLowestDenomination
              currency
              isRecurring
              timeInterval
              stripePriceId
              courseId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourseSubscriptionModelManyToManyRelation = /* GraphQL */ `
  mutation DeleteCourseSubscriptionModelManyToManyRelation(
    $input: DeleteCourseSubscriptionModelManyToManyRelationInput!
    $condition: ModelCourseSubscriptionModelManyToManyRelationConditionInput
  ) {
    deleteCourseSubscriptionModelManyToManyRelation(
      input: $input
      condition: $condition
    ) {
      id
      courseId
      subscriptionModelId
      course {
        id
        name
        releaseStatus
        userAccessType
        image
        sortOrder
        chapters {
          items {
            id
            name
            releaseStatus
            userAccessType
            courseId
            sortOrder
            course {
              id
              name
              releaseStatus
              userAccessType
              image
              sortOrder
              createdAt
              updatedAt
            }
            topics {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        subscriptionModels {
          items {
            id
            courseId
            subscriptionModelId
            course {
              id
              name
              releaseStatus
              userAccessType
              image
              sortOrder
              createdAt
              updatedAt
            }
            subscriptionModel {
              id
              amountInLowestDenomination
              currency
              isRecurring
              timeInterval
              stripePriceId
              courseId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      subscriptionModel {
        id
        amountInLowestDenomination
        currency
        isRecurring
        timeInterval
        stripePriceId
        courseId
        courses {
          items {
            id
            courseId
            subscriptionModelId
            course {
              id
              name
              releaseStatus
              userAccessType
              image
              sortOrder
              createdAt
              updatedAt
            }
            subscriptionModel {
              id
              amountInLowestDenomination
              currency
              isRecurring
              timeInterval
              stripePriceId
              courseId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
