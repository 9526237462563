import "./HomePage.css";

import React, { useEffect, useState } from "react";

import { Course } from "../../api/graphql/schemas";
import { CourseBox } from "../../components/course-box/CourseBox";
import { courseComparator } from "../../utils/CourseUtil";
import {
  getAllCoursesShallowAPI,
  getAllCoursesSubscriptionModelsAPI,
} from "../../api/CourseApis";
import { useSelector } from "react-redux";

type HomePageProps = {};

export const HomePage: React.FunctionComponent<HomePageProps> = ({}) => {
  const [allCourses, setAllCourses] = useState<Course[]>();
  const allSubscriptions = useSelector(
    (state: any) => state.accountState.allSubscriptions
  );

  useEffect(() => {
    getAllCoursesSubscriptionModelsAPI().then(
      (data) => {
        data?.sort((course1, course2) => courseComparator(course1, course2));
        setAllCourses(data);
      },
      (error) => {
        // console.log("error: " + error);
      }
    );
  }, []);

  return (
    <div className="home-page">
      <div className="home-page-top">
        <div>
          <h1>Launch your career</h1>
          <h3>With 50 years of combined FAANG experience.</h3>
        </div>
      </div>
      {allCourses && (
        <div className="courses-container">
          {allCourses.map((course) => (
            <CourseBox key={course.id} course={course} />
          ))}
        </div>
      )}
    </div>
  );
};
