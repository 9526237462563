const cdnPath = "https://d3jdnfk8czaeld.cloudfront.net/";
const imageExtension = ".svg";

export enum ImageSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export const getFullImagePath = (imageName: string | null | undefined) => {
  return `${cdnPath}${imageName}${imageExtension}`;
};
