import { AnyAction } from "redux";
import { Custom_User } from "../api/CustomSchemas";

export const userReducer = (state = initialUserState, action: AnyAction) => {
  const { type, payload } = action;
  switch (type) {
    case UserReducerActionTypes.SET_USER:
      return { ...state, user: payload };
  }

  return state;
};

export type UserState = {
  user?: Custom_User;
};

const initialUserState: UserState = {
  user: undefined,
};

export enum UserReducerActionTypes {
  SET_USER = "SET_USER",
}
