import React from "react";
import { DisplayStrings } from "../../utils/DisplayStrings";
import comingSoonImage from "../../assets/under_construction.svg";
import "./ComingSoon.css";

type ComingSoonProps = {};

export const ComingSoon: React.FunctionComponent<ComingSoonProps> = ({}) => {
  return (
    <div className="coming-soon-cntnr">
      <div className="message">{DisplayStrings.COMING_SOON}</div>
      <img className="coming-soon-img" src={comingSoonImage} />
    </div>
  );
};
