import React, { useEffect, useState } from "react";
import {
  getCourseChapterTopicAPI,
  getCourseChapterTopicSubscriptionModelAPI,
} from "../../api/CourseApis";
import { useSearchParams } from "react-router-dom";
import { TopicContentPlayer } from "../topic-content-player/TopicContentPlayer";
import { CourseNavigator } from "../course-navigator/CourseNavigator";
import "./CoursePlayer.css";
import { getSelectecTopicFromCourse } from "../../utils/CourseUtil";
import { Course, Topic } from "../../api/graphql/schemas";
import { store } from "../../state/store";
import { CourseReducerActionTypes } from "../../state/course.Reducer";
import { useSelector } from "react-redux";
import { AccountReducerActionTypes } from "../../state/account.Reducer";
import { getSubscriptionForCourse } from "../../utils/AccountUtil";

type CoursePlayerProps = {};

export const CoursePlayer: React.FunctionComponent<
  CoursePlayerProps
> = ({}) => {
  // URL Parameters
  let [searchParams] = useSearchParams();
  let courseIdParam = searchParams.get("courseid");
  let topicIdParam = searchParams.get("topicid");

  // Getting values from Store
  const allSubscriptions = useSelector(
    (state: any) => state.accountState.allSubscriptions
  );

  // Local States
  const [course, setCourse] = useState<Course | null | undefined>();
  const [topic, setTopic] = useState<Topic | null>();

  useEffect(() => {
    if (courseIdParam) {
      getCourseChapterTopicSubscriptionModelAPI(courseIdParam).then(
        (course) => {
          setCourse(course);
          store.dispatch({
            type: CourseReducerActionTypes.SET_SELECTED_COURSE,
            payload: course,
          });
        },
        (error) => {
          // console.log("error: " + error);
        }
      );
    }
  }, [courseIdParam]);

  useEffect(() => {
    if (course) {
      let topic = getSelectecTopicFromCourse(course, topicIdParam);
      setTopic(topic);
      store.dispatch({
        type: CourseReducerActionTypes.SET_SELECTED_TOPIC,
        payload: topic,
      });
    }
  }, [course, topicIdParam]);

  useEffect(() => {
    // If course ID is present, let's find out subscription for this course.
    // When subscriptions are null (which means we fetched from server but did not find anything)
    // We would like to dispatch specifically and getSubscriptionForCourse preserves undefined vs null.
    if (course && course.subscriptionModels && allSubscriptions !== undefined) {
      store.dispatch({
        type: AccountReducerActionTypes.SET_SUBSCRIPTION_FOR_SELECTED_COURSE,
        payload: getSubscriptionForCourse(course, allSubscriptions),
      });
    }
  }, [course, allSubscriptions]);

  return (
    <div className="crs-plyr">
      {course && topic && (
        <>
          <CourseNavigator />
          <TopicContentPlayer />
        </>
      )}
    </div>
  );
};
