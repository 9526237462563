import { API, Auth, graphqlOperation } from "aws-amplify";
const ACCOUNT_API_NAME = "account";

export const getSubscriptionsAPI = async (body: any) => {
  return await API.post(
    ACCOUNT_API_NAME,
    "/account/subscriptions",
    await takeBodyApplyAuthHeader(body)
  );
};

export const cancelSubscriptionAPI = async (body: any) => {
  return await API.post(
    ACCOUNT_API_NAME,
    "/account/unsubscribe",
    await takeBodyApplyAuthHeader(body)
  );
};

export const renewSubscriptionAPI = async (body: any) => {
  return await API.post(
    ACCOUNT_API_NAME,
    "/account/renewsubscription",
    await takeBodyApplyAuthHeader(body)
  );
};

export const getTransactionsAPI = async (body: any) => {
  return await API.post(
    ACCOUNT_API_NAME,
    "/account/txns",
    await takeBodyApplyAuthHeader(body)
  );
};

export const subscribeAPI = async (body: any) => {
  return await API.post(
    ACCOUNT_API_NAME,
    "/account/subscribe",
    await takeBodyApplyAuthHeader(body)
  );
};

const takeBodyApplyAuthHeader = async (body: any) => {
  const session = await Auth.currentSession();

  // Next, get the ID token from the session
  const idToken = session.getIdToken().getJwtToken();

  let headers = {
    Authorization: idToken,
  };

  return {
    body: body,
    headers: headers,
  };
};
