import { API } from "aws-amplify";
import { checkIfUserAuthenticated } from "../utils/AuthUtil";
import {
  getCourse_Chapter_Topic_Custom,
  getCourse_Chapter_Topic_SubscriptionModels_Custom,
  getSubtopic_Content_Custom,
  getTopic_Subtopic_Content_Custom,
  getTopic_Subtopic_NoContent_Custom,
  getUser_Custom,
  listCourses_Shallow_Custom,
  listCourses_SubscriptionModels_Custom,
} from "./graphql/CustomQueries";
import { Course, Subtopic, Topic, User } from "./graphql/schemas";

type GetCourseResult = {
  data: {
    getCourse: {
      item: Course;
    };
  };
};

// Retrieves all courses in the system.
export const getAllCoursesShallowAPI = async (): Promise<
  Course[] | undefined
> => {
  type ListCoursesResult = {
    data: {
      listCourses: {
        items: Course[];
      };
    };
  };

  let courseArrayResult;
  try {
    const graphqlResult = await makeGraphqlQuery(listCourses_Shallow_Custom);

    courseArrayResult = (graphqlResult as ListCoursesResult)?.data?.listCourses
      ?.items;
  } catch (error) {
    console.log(error);
  }

  return courseArrayResult;
};

// Retrieves all courses in the system.
export const getAllCoursesSubscriptionModelsAPI = async (): Promise<
  Course[] | undefined
> => {
  type ListCoursesResult = {
    data: {
      listCourses: {
        items: Course[];
      };
    };
  };

  let courseArrayResult;
  try {
    const graphqlResult = await makeGraphqlQuery(
      listCourses_SubscriptionModels_Custom
    );

    courseArrayResult = (graphqlResult as ListCoursesResult)?.data?.listCourses
      ?.items;
  } catch (error) {
    console.log(error);
  }

  return courseArrayResult;
};

// Retrieves a specific ourse. Should also retrieve Chapters and topics in it.
export const getCourseChapterTopicAPI = async (
  idArg: string
): Promise<Course | null> => {
  type GetCourseResult = {
    data: {
      getCourse: Course;
    };
  };

  let courseResult = null;
  let input = {
    id: idArg,
  };

  try {
    const graphqlResult = await makeGraphqlQuery(
      getCourse_Chapter_Topic_Custom,
      input
    );

    courseResult = (graphqlResult as GetCourseResult)?.data?.getCourse;
  } catch (error) {
    console.log(error);
  }

  return courseResult;
};

// Retrieves a specific ourse. Should also retrieve Chapters, topics and SubscriptionModels in it.
export const getCourseChapterTopicSubscriptionModelAPI = async (
  idArg: string
): Promise<Course | null> => {
  type GetCourseResult = {
    data: {
      getCourse: Course;
    };
  };

  let courseResult = null;
  let input = {
    id: idArg,
  };

  try {
    const graphqlResult = await makeGraphqlQuery(
      getCourse_Chapter_Topic_SubscriptionModels_Custom,
      input
    );

    courseResult = (graphqlResult as GetCourseResult)?.data?.getCourse;
  } catch (error) {
    console.log(error);
  }

  return courseResult;
};

// Retrieves a specific ourse. Should also retrieve Chapters and topics in it.
export const getTopicSubtopicAndNoContentAPI = async (
  idArg: string
): Promise<Topic | null> => {
  type GetTopicResult = {
    data: {
      getTopic: Topic;
    };
  };

  let topicResult = null;
  let input = {
    id: idArg,
  };

  try {
    const graphqlResult = await makeGraphqlQuery(
      getTopic_Subtopic_NoContent_Custom,
      input
    );

    topicResult = (graphqlResult as GetTopicResult)?.data?.getTopic;
  } catch (error) {
    console.log(error);
  }

  return topicResult;
};

// Retrieves a specific ourse. Should also retrieve Chapters and topics in it.
export const getTopicSubtopicAndContentAPI = async (
  idArg: string
): Promise<Topic | null> => {
  type GetTopicResult = {
    data: {
      getTopic: Topic;
    };
  };

  let topicResult = null;
  let input = {
    id: idArg,
  };

  try {
    const graphqlResult = await makeGraphqlQuery(
      getTopic_Subtopic_Content_Custom,
      input
    );

    topicResult = (graphqlResult as GetTopicResult)?.data?.getTopic;
  } catch (error) {
    console.log(error);
  }

  return topicResult;
};

export const getSubtopicAndContentAPI = async (
  idArg: string
): Promise<Subtopic | null> => {
  type GetSubtopicResult = {
    data: {
      getSubtopic: Subtopic;
    };
  };

  let subtopicResult = null;
  let input = {
    id: idArg,
  };

  try {
    const graphqlResult = await makeGraphqlQuery(
      getSubtopic_Content_Custom,
      input
    );

    subtopicResult = (graphqlResult as GetSubtopicResult)?.data?.getSubtopic;
  } catch (error) {
    console.log(error);
  }

  return subtopicResult;
};

export const getUserAPI = async (username: string) => {
  type GetUserResult = {
    data: {
      getUser: User;
    };
  };

  let input = {
    username: username,
  };

  let userResult = null;
  try {
    const graphqlResult = await makeGraphqlQuery(getUser_Custom, input);

    userResult = (graphqlResult as GetUserResult)?.data?.getUser;
  } catch (error) {
    console.log(error);
  }

  return userResult;
};

const makeGraphqlQuery = async (query: any, input?: any) => {
  let isUserAuthenticated = await checkIfUserAuthenticated();
  const graphqlResult = await API.graphql({
    query: query,
    variables: input,
    authMode: isUserAuthenticated ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
  });

  return graphqlResult;
};
