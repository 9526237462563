import React from "react";
import {
  cancelSubscriptionAPI,
  renewSubscriptionAPI,
  subscribeAPI,
} from "../../api/AccountApi";
import { Custom_Subscription_SubscriptionModel } from "../../api/CustomSchemas";
import { Course } from "../../api/graphql/schemas";
import {
  getSubscriptionModelPriceDisplay,
  getTimeSpanMembershipDisplay,
} from "../../utils/AccountUtil";
import { DisplayStrings } from "../../utils/DisplayStrings";
import { getFullImagePath } from "../../utils/ImageUtil";
import { getFormattedDate } from "../../utils/MiscUtil";
import "./SubscriptionBox.css";

type SubscriptionBoxProps = {
  course: Course;
  subscriptionAndModel: Custom_Subscription_SubscriptionModel;
};

export const SubscriptionBox: React.FunctionComponent<SubscriptionBoxProps> = ({
  course,
  subscriptionAndModel,
}) => {
  const cancelSubscription = async () => {
    const body = {
      courseId: course.id,
    };

    // TODO: Show appropriate message.
    let data = await cancelSubscriptionAPI(body);
    window.location.reload();
  };

  const renewSubscription = async () => {
    const body = {
      courseId: course.id,
    };

    // TODO: Show appropriate message.
    let data = await renewSubscriptionAPI(body);
    window.location.reload();
  };

  return (
    <div className="sub-bx-cntr">
      <div className="sub-box-img">
        {course.image && (
          <img src={getFullImagePath(course.image)} alt={course.name} />
        )}
      </div>
      <div className="sub-box-dtls">
        <div className="crs-nm">{course.name}</div>
        <table>
          <tr>
            <td>Current Plan:</td>
            <td>
              {DisplayStrings.SPACE}
              {getSubscriptionModelPriceDisplay(
                subscriptionAndModel.subscriptionModel
              )}
            </td>
          </tr>
          <tr>
            <td>
              {subscriptionAndModel.subscription.cancel_at_period_end
                ? "Expires On:"
                : "Renews On:"}
            </td>
            <td>
              {subscriptionAndModel.subscription.cancel_at_period_end
                ? getFormattedDate(subscriptionAndModel.subscription.cancel_at)
                : getFormattedDate(
                    subscriptionAndModel.subscription.current_period_end
                  )}
            </td>
          </tr>
        </table>

        {subscriptionAndModel.subscription.cancel_at_period_end && (
          <button onClick={renewSubscription}>
            {DisplayStrings.RENEW_SUBSCRIPTION_BUTTON_TEXT}
          </button>
        )}
        {!subscriptionAndModel.subscription.cancel_at_period_end && (
          <button onClick={cancelSubscription}>
            {DisplayStrings.CANCEL_SUBSCRIPTION_BUTTON_TEXT}
          </button>
        )}
      </div>
    </div>
  );
};
