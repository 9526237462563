import React from "react";
import { DisplayStrings } from "../../utils/DisplayStrings";
import "./PaymentRequired.css";
import { useSelector } from "react-redux";
import { SubscriptionModelComponent } from "../subscription-model/SubscriptionModelComponent";

type PaymentRequiredProps = {};

export const PaymentRequired: React.FunctionComponent<
  PaymentRequiredProps
> = ({}) => {
  const course = useSelector((state: any) => state.courseState.selectedCourse);

  return (
    <div className="pymnt-Rqrd-cntnr">
      <div className="message">{DisplayStrings.PAYMENT_REQUIRED}</div>
      <div className="subsc-model-cntnr">
        {course?.subscriptionModels?.items?.map(
          (item: any) =>
            item && (
              <SubscriptionModelComponent
                key={item.subscriptionModel.id}
                subModel={item.subscriptionModel}
              />
            )
        )}
      </div>
    </div>
  );
};
