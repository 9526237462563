import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Custom_Subscription } from "../../api/CustomSchemas";
import { Course, ReleaseStatus } from "../../api/graphql/schemas";
import { getSubscriptionForCourse } from "../../utils/AccountUtil";
import { releaseStatusToUserFriendlyName } from "../../utils/CourseUtil";
import { getFullImagePath, ImageSize } from "../../utils/ImageUtil";
import { ComingSoonSubscript } from "../coming-soon-subscript/ComingSoonSubscript";
import "./CourseBox.css";

type CourseBoxProps = {
  course: Course;
  onClick?: () => void;
};

export const CourseBox: React.FunctionComponent<CourseBoxProps> = ({
  course,
}) => {
  // Getting values from Store
  const allSubscriptions = useSelector(
    (state: any) => state.accountState.allSubscriptions
  );

  const subscriptionForCourse = getSubscriptionForCourse(
    course,
    allSubscriptions
  );

  return (
    <>
      {course.releaseStatus === ReleaseStatus.COMING_SOON ||
      course.releaseStatus === ReleaseStatus.ALPHA ||
      course.releaseStatus === ReleaseStatus.BETA ||
      course.releaseStatus === ReleaseStatus.NONE ? (
        courseBoxTemplate(course, true)
      ) : (
        <Link to={`/course?courseid=${course.id}`}>
          {courseBoxTemplate(course, false, subscriptionForCourse)}
        </Link>
      )}
    </>
  );
};

export const courseBoxTemplate = (
  course: Course,
  showComingSoon: boolean,
  subscriptionForCourse?: Custom_Subscription | null
) => {
  return (
    <div className={showComingSoon ? "crs-bx-cntr-no-click" : "crs-bx-cntr"}>
      <div className="course-box-img">
        {course.image && (
          <img src={getFullImagePath(course.image)} alt={course.name} />
        )}
      </div>
      <div className="course-box-dtls">
        <div className="crs-nm">{course.name}</div>
        {showComingSoon && (
          <div className="crs-bx-soon">
            <ComingSoonSubscript />
          </div>
        )}
        {subscriptionForCourse && <div className="sbscrbd">Subscribed</div>}
      </div>
    </div>
  );
};
