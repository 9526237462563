import {
  Custom_Subscription,
  Custom_Subscription_SubscriptionModel,
  Custom_Txn,
} from "../api/CustomSchemas";
import {
  Course,
  Currency,
  SubscriptionModel,
  TimeInterval,
} from "../api/graphql/schemas";
import { DisplayStrings } from "./DisplayStrings";

export const getTimeSpanMembershipDisplay = (subModel: SubscriptionModel) => {
  if (subModel.isRecurring) {
    if (subModel.timeInterval === TimeInterval.ONE_MONTH) {
      return DisplayStrings.SUBSCRIPTION_MODEL_MONTHLY_MEMBERSHIP;
    } else if (subModel.timeInterval === TimeInterval.THREE_MONTHS) {
      return DisplayStrings.SUBSCRIPTION_MODEL_QUARTERLY_MEMBERSHIP;
    }
  } else {
    if (subModel.timeInterval === TimeInterval.ONE_MONTH) {
      return DisplayStrings.SUBSCRIPTION_MODEL_ONCE_MONTH_MEMBERSHIP;
    } else if (subModel.timeInterval === TimeInterval.THREE_MONTHS) {
      return DisplayStrings.SUBSCRIPTION_MODEL_ONCE_QUARTER_MEMBERSHIP;
    }
  }
};

export const getSubscriptionModelPriceDisplay = (
  subModel: SubscriptionModel
) => {
  if (subModel.isRecurring) {
    if (subModel.timeInterval === TimeInterval.ONE_MONTH) {
      return `${subModel.amountInLowestDenomination / 100}${
        DisplayStrings.SPACE
      }${getCurrencyText(subModel.currency)}${DisplayStrings.SPACE}/${
        DisplayStrings.SPACE
      }${DisplayStrings.SUBSCRIPTION_MODEL_MONTHLY_PRICE_DENOMINATION}`;
    } else if (subModel.timeInterval === TimeInterval.THREE_MONTHS) {
      return `${subModel.amountInLowestDenomination / 100}${
        DisplayStrings.SPACE
      }${getCurrencyText(subModel.currency)}${DisplayStrings.SPACE}/${
        DisplayStrings.SPACE
      }${DisplayStrings.SUBSCRIPTION_MODEL_QUARTERLY_PRICE_DENOMINATION}`;
    }
  } else {
    if (subModel.timeInterval === TimeInterval.ONE_MONTH) {
      return `${subModel.amountInLowestDenomination / 100}${
        DisplayStrings.SPACE
      }${getCurrencyText(subModel.currency)}`;
    } else if (subModel.timeInterval === TimeInterval.THREE_MONTHS) {
      return `${subModel.amountInLowestDenomination / 100}${
        DisplayStrings.SPACE
      }${getCurrencyText(subModel.currency)}`;
    }
  }
};

export const getCurrencyText = (currency: Currency) => {
  if (currency === Currency.USD) {
    return DisplayStrings.CURRENCY_USD;
  }
};

export const getTxnAmountDisplay = (txn: Custom_Txn) => {
  return `${txn.amount_captured / 100}${
    DisplayStrings.SPACE
  } ${txn.currency?.toUpperCase()}`;
};

/*
  subscriptions/subscription: Can have 3 different values
  1. undefined: Means nothing has been fetched from server, either we did not make a request or user is not authenticated so we did not make a fetch for subscriptions/subscription.
  2. null: We made a request to fetch but there were subscriptions/subscription. Same applies for subscriptionforselectcourse
  3. Actual Value.
*/
export const getSubscriptionForCourse = (
  course: Course,
  subscriptions: Custom_Subscription[] | null | undefined
) => {
  if (subscriptions === undefined) return undefined;

  let subVar: Custom_Subscription | null = null;
  subscriptions?.map((subscription) => {
    course?.subscriptionModels?.items?.map((subscriptionModel) => {
      if (
        subscription?.items?.data[0]?.price?.id ===
        subscriptionModel?.subscriptionModel.stripePriceId
      ) {
        subVar = subscription;
        return subVar;
      }
    });
  });

  return subVar;
};

export const getSubscriptionAndModelForCourse = (
  course: Course,
  subscriptions: Custom_Subscription[] | null | undefined
) => {
  if (subscriptions === undefined) return undefined;

  let result: Custom_Subscription_SubscriptionModel | null = null;
  subscriptions?.map((subscription) => {
    course?.subscriptionModels?.items?.map((subscriptionModel) => {
      if (
        subscription?.items?.data[0]?.price?.id ===
        subscriptionModel?.subscriptionModel.stripePriceId
      ) {
        result = {
          subscriptionModel: subscriptionModel?.subscriptionModel,
          subscription: subscription,
        };

        return result;
      }
    });
  });

  return result;
};
