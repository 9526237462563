import React, { useEffect, useState } from "react";
import { Course } from "../../api/graphql/schemas";
import { getAllCourses_Prices_Admin_API } from "../api/AdminAPI";
import { courseComparator } from "../../utils/CourseUtil";

import "./CoursesAndPrices.css";

type CoursesAndPricesProps = {};

export const CoursesAndPrices: React.FunctionComponent<
  CoursesAndPricesProps
> = ({}) => {
  const [coursesAndPricesState, setCoursesAndPricesState] = useState<
    Course[] | undefined
  >([]);

  useEffect(() => {
    const body = {};

    getAllCourses_Prices_Admin_API().then(
      (data) => {
        data?.sort((course1, course2) => courseComparator(course1, course2));
        console.log("Called Inside");
        setCoursesAndPricesState(data);
      },
      (error) => {
        // console.log("error: " + error);
      }
    );
  }, []);

  return (
    <div>
      {coursesAndPricesState && (
        <div>
          {coursesAndPricesState.map((course) => (
            <div>
              <table className="table">
                <tr className="crs-head">
                  <td>Course ID</td>
                  <td>Course Name</td>
                </tr>
                <tr>
                  <td>{course.id}</td>
                  <td>{course.name}</td>
                </tr>
                <tr className="sub-head">
                  <td>Subscription Model ID</td>
                  <td>amountInLowestDenomination</td>
                  <td>currency</td>
                  <td>isRecurring</td>
                  <td>timeInterval</td>
                  <td>stripePriceId</td>
                </tr>
                {course.subscriptionModels?.items.map((item) => (
                  <tr>
                    <td>{item?.subscriptionModel.id}</td>
                    <td>
                      {item?.subscriptionModel.amountInLowestDenomination}
                    </td>
                    <td>{item?.subscriptionModel.currency}</td>
                    <td>
                      {item?.subscriptionModel.isRecurring ? "true" : "false"}
                    </td>
                    <td>{item?.subscriptionModel.timeInterval}</td>
                    <td>{item?.subscriptionModel.stripePriceId}</td>
                  </tr>
                ))}
              </table>
              <br />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
