import "./App.css";

import awsconfig from "./aws-exports";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AdminPage } from "./admin/views/admin-page/AdminPage";
import { Amplify } from "aws-amplify";
import { ContactUsPage } from "./views/contact-us/ContactUsPage";
import { CoursePlayer } from "./components/course-player/CoursePlayer";
import { HomePage } from "./views/home-page/HomePage";
import { NavigationHeader } from "./components/navigation-header/NavigationHeader";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { AccountPage } from "./views/account-page/AccountPage";
import { useEffect, useState } from "react";
import { store } from "./state/store";
import { UserReducerActionTypes } from "./state/user.Reducer";
import { getSubscriptionsAPI } from "./api/AccountApi";
import { AccountReducerActionTypes } from "./state/account.Reducer";
import { getRedirectUrl } from "./utils/MiscUtil";

/** Configuring awsconfig */
const [localRedirectSignIn, stagingRedirectSignIn, productionRedirectSignIn] =
  awsconfig.oauth.redirectSignIn.split(",");

const [
  localRedirectSignOut,
  stagingRedirectSignOut,
  productionRedirectSignOut,
] = awsconfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    redirectSignIn: getRedirectUrl(
      localRedirectSignIn,
      stagingRedirectSignIn,
      productionRedirectSignIn
    ),
    redirectSignOut: getRedirectUrl(
      localRedirectSignOut,
      stagingRedirectSignOut,
      productionRedirectSignOut
    ),
  },
};

Amplify.configure(updatedAwsConfig);
/** Configuried awsconfig */

const App = () => {
  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    const body = {};

    // Many of these actions are dependent on User. Hence doing all of them at one place.
    if (user) {
      // Dispatching User
      store.dispatch({
        type: UserReducerActionTypes.SET_USER,
        payload: user.attributes,
      });

      // Subscriptions are available only if User is available.
      // Getting all the subscriptions for user.
      /*
        subscriptions/subscription: Can have 3 different values
        1. undefined: Means nothing has been fetched from server, either we did not make a request or user is not authenticated so we did not make a fetch for subscriptions/subscription.
        2. null: We made a request to fetch but there were subscriptions/subscription. Same applies for subscriptionforselectcourse
        3. Actual Value.
      */
      getSubscriptionsAPI(body).then((response) => {
        let subscriptions = null;
        if (
          response &&
          response.subscriptions &&
          response.subscriptions.length > 0
        ) {
          subscriptions = response.subscriptions;
        }

        // Set all subscriptions.
        store.dispatch({
          type: AccountReducerActionTypes.SET_ALL_SUBSCRIPTIONS,
          payload: subscriptions,
        });
      });
    }
  }, [user]);

  return (
    <div className="app-cntr">
      <BrowserRouter>
        <NavigationHeader />
        <Routes>
          <Route path="/*" index element={<HomePage />} />
          <Route path="/courses" index element={<HomePage />} />
          <Route path="/home" index element={<HomePage />} />
          <Route path="course/*" element={<CoursePlayer />} />
          <Route path="admin/*" element={<AdminPage />} />
          <Route path="contactus/*" element={<ContactUsPage />} />
          <Route path="account/*" element={<AccountPage />} />
        </Routes>
      </BrowserRouter>
      {/* <Footer/> */}
    </div>
  );
};

export default App;
